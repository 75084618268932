/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import { useSearchParams, Navigate } from "react-router-dom";
import { connectUs, setLoading } from "store/actions/accountActions";
import { useDispatch, useSelector } from "react-redux";

const AddAccount = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.accounts.loading);
  const fetched = useSelector((state) => state.accounts.fetched);
  const error = useSelector((state) => state.accounts.error);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (code) {
      dispatch(setLoading(true));
      dispatch(connectUs(code));
    }
  }, [code]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  if ((fetched && !loading) || (error && !loading)) {
    return <Navigate to="/accounts" />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8} height="80vh">
            <MDProgress value={progress} variant="gradient" />
            <br />
            <div>Your US accounts are currently loading, please check back shortly. This takes about 3 hours, depending on the size of your account. This is a one time wait...</div>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
};

export default AddAccount;