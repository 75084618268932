/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import SaveIcon from "@mui/icons-material/Save";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";

import Cookies from 'universal-cookie';

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDButtonLoading from "components/MDButtonLoading";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import bgImage from "assets/images/background.png";

import setAlert from "store/actions/alertActions";
import { setLoading, clearErrors, loginUser, checkUser } from "store/actions/userActions";
import ConfirmEmailDialog from "../components/ConfirmEmailDialog";

function Basic() {
  const isAuthenticated = useSelector((state) => state.users.isAuthenticated);

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    // eslint-disable-next-line no-restricted-syntax
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  const dispatch = useDispatch();
  const error = useSelector((state) => state.users.error);

  // const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
  const openVerificationModal = useSelector((state) => state.users.openVerificationModal);
  const loading = useSelector((state) => state.users.loading);
  // const user = useSelector((state) => state.users.user);
  const [formData, setFormData] = useState({ email: "", password: "" });

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  const handleLogin = () => {
    dispatch(setLoading());
    dispatch(loginUser(formData));
  };
  const setOpen = (value) => {
    dispatch({ type: "SET_OPEN_VERIFICATION_MODAL", payload: value });
  };

  if (isAuthenticated && !loading) {
    return <Navigate to="/accounts" />;
  }

  return (
    // <BasicLayout image={bgImage}>
    <BasicLayout image={bgImage}>
      <Card>
        {/* <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        > */}
        {/* <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Welcome to Scalomatic
          </MDTypography> */}
        {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        {/* </MDBox> */}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onChange={(event) => setFormData({ ...formData, email: event.target.value })}
              />
            </MDBox>
            <MDBox>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={(event) => setFormData({ ...formData, password: event.target.value })}
              />
            </MDBox>
            <MDBox mb={5} display="flex" justifyContent="flex-end" pr={2} pt={1}>
              <MDTypography
                component={Link}
                to="/authentication/forgot"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forgot password?
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButtonLoading
                loading={loading}
                loadingPosition="start"
                // eslint-disable-next-line react/jsx-no-useless-fragment
                startIcon={<></>}
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => handleLogin()}
              >
                sign in
              </MDButtonLoading>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}

                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <ConfirmEmailDialog open={openVerificationModal} setOpen={setOpen} />
      </Card>
    </BasicLayout>
  );
}

export default Basic;
