/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
// import axios from "axios";

import { CLEAR_ERRORS, SET_CAMPAIGNS_LOADING, SET_CAMPAIGNS_OPTIMIZE_LOADING, SET_CAMPAIGN } from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

export const getCampaignAdgroups = ({ profileId, campaignId, campaigns }) => {
  return factory.get({
    url: `${URL}/campaigns/adgroups?profileId=${profileId}&campaignId=${campaignId}`, type: "GET_ADGROUPS", typeFail: "CAMPAIGNS_FAIL",
    rest: {
      campaign: campaigns?.find(campaign => campaign.campaignId === campaignId)
    }
  });
}

export const compareRangeData = (compareRangeConfiguration) => {
  return factory.get({ url: `${URL}/profiles/history`, params: compareRangeConfiguration, type: "GET_ADGROUPS", typeFail: "CAMPAIGNS_FAIL" });
}

// Set Campaign Optimize
export const setOptimizeCampaign = (campaign, profileId, optimize) =>
  factory.patch(
    `${URL}/campaigns/optimize`,
    { optimizing: optimize, campaignId: campaign, profileId },
    "UPDATED_CAMPAIGN_OPTIMIZE",
    "CAMPAIGNS_FAIL"
  );


export const saveCampaignTargetAcos = (acosLevelList) => {
  const objEntries = Object.entries(acosLevelList);

  return factory.patch(
    `${URL}/campaigns/target-acos`,
    { campaignId: objEntries[0][0], targetAcos: Number(objEntries[0][1]) },
    "UPDATED_TARGET_ACOS",
    "ACCOUNTS_FAIL"
  );
}

export const setCampaign = (campaign) => ({ type: SET_CAMPAIGN, campaign });

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading -- Used for login
export const setLoading = () => ({ type: SET_CAMPAIGNS_LOADING });
export const setOptimizeLoading = () => ({ type: SET_CAMPAIGNS_OPTIMIZE_LOADING });
