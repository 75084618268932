/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-reset-cover.jpeg";
import bgImage from "assets/images/background.png";

import setAlert from "store/actions/alertActions";
import { setLoading, clearErrors, forgotPass } from "store/actions/userActions";

function Cover() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.users.loading);
  const error = useSelector((state) => state.users.error);
  const linkSent = useSelector((state) => state.users.linkSent);
  const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (error && !linkSent) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [linkSent, error, setAlert, clearErrors]);

  useEffect(() => {
    if (linkSent) {
      dispatch(setAlert(error, "success"));
      dispatch(clearErrors());
    }
  }, [linkSent, setAlert, clearErrors, error]);

  function ValidateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    dispatch(setAlert("You have entered an invalid email address!", "error"));
    dispatch(clearErrors());

    return false;
  }

  const submitReset = () => {
    const valid = ValidateEmail(email);
    if (valid) {
      dispatch(setLoading());
      dispatch(forgotPass(email));
    }
  };

  if (linkSent && !isAuthenticated) {
    return <Navigate to="/authentication/sign-in" />;
  }
  if (isAuthenticated) {
    return <Navigate to="/accounts" />;
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Forgot your Password?
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail with a reset password link.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={12} display="flex" justifyContent="flex-end" pr={2}>
              <MDTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Back to Login Screen
              </MDTypography>
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={submitReset}
                disabled={loading}
              >
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
