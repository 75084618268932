import { useState, useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";
// Calendar value {startDate: '2023-07-03', endDate: '2023-07-04'}
// eslint-disable-next-line func-names
const Calendar = function (props) {

    // eslint-disable-next-line react/prop-types,prefer-const
    const { changeDateRange, placeholder, defaultValue = { startDate: null, endDate: null } } = props;
    const { startDate, endDate } = defaultValue;

    const [value, setValue] = useState({
        startDate,
        endDate
    });

    const handleValueChange = (newValue) => {
        const lastDate = newValue.endDate;
        if (new Date(lastDate) > new Date()) {
            const month = (new Date().getMonth() + 1);
            const day = new Date().getDate();
            const year = new Date().getFullYear();

            newValue.endDate = [year, month, day].join('-');
        }
        changeDateRange(newValue);
        setValue(newValue);
    }

    return (
        <Datepicker
            configs={{
                footer: {
                    cancel: "Cancel",
                    apply: "Apply"
                }
            }}
            value={value || defaultValue}
            onChange={handleValueChange}
            placeholder={placeholder}
            showShortcuts="true"
            openPickerIcon={() => { }}
            showFooter="true"
            // containerClassName="calendar-box"
            inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full pl-5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
    );
}
export default Calendar;