// eslint-disable-next-line import/prefer-default-export
export const countryCurrency = {
  US: '$',
  CA: 'CDN$',
  MX: 'MXN$',
  BR: 'R$',
  UK: '£',
  DE: '€',
  FR: '€',
  ES: '€',
  IT: '€',
  AE: 'AED',
  SA: 'SAR',
  NL: '€',
  SE: 'SEK',
  TR: 'TRY',
  PL: 'PLN',
  BE: '€',
  JP: '¥',
  AU: 'A$',
  SG: 'SGD',
}

export const getCountryCurrency = (country = 'us') => countryCurrency[country];