/* eslint-disable import/prefer-default-export */

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// export const getPlans = () =>
//     factory.post(
//         {},
//         `${URL}/payments/get-plans`,
//         "PAYMENT_CONNECT",
//         "PAYMENT_FAIL"
//     )

// GET /subscriptions/plans
export const getPlans = () =>
    factory.get({
        url: `${URL}/subscriptions/plans`,
        type: "PAYMENT_CONNECT",
        typeFail: "PAYMENT_FAIL"
    })

// POST /subscriptions/checkout-session
// action="/api/v1/payments/create-checkout-session"
// accountId
// optimizing
export const checkOutSession = ({ optimizing, profileId }) =>
    factory.post(
        { profileId, optimizing },
        `${URL}/subscriptions/checkout-session`,
        "PAYMENT_CONNECT",
        "PAYMENT_FAIL",
    )

// PATCH /subscriptions
export const checkWholeSub = () =>
    factory.patch(
        `${URL}/subscriptions`,
        {},
        "PAYMENT_CONNECT",
        "PAYMENT_FAIL"
    )

// PATCH /subscriptions/cancel/:profileId
export const cancelSubscription = (profile, optimize) =>
    factory.patch(
        `${URL}/subscriptions/cancel/${profile}`,
        {},
        "PAYMENT_CONNECT",
        "ACCOUNTS_FAIL"
    );

// GET /subscriptions/session
export const retrieveSession = () =>
    factory.get(
        {
            url: `${URL}/subscriptions/session`,
            type: "PAYMENT_CONNECT",
            typeFail: "PAYMENT_FAIL"
        }
    )

// POST /subscriptions/register-customer
export const registerCustomer = (email) =>
    factory.post(
        { email },
        `${URL}/subscriptions/register-customer`,
        "PAYMENT_CONNECT",
        "PAYMENT_FAIL"
    )