/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'universal-cookie';
import setAlert from "store/actions/alertActions";

import {
    getSubscriptions,
} from "store/actions/accountActions";

import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setOptimizeKeyword, setOptimizeLoading } from "store/actions/adGroupActions";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { CircularProgress } from "@mui/material";

import { getAccountById } from "../../../store/actions/accountActions";
import { saveKeywordTargetAcos } from "../../../store/actions/adGroupActions";
import { getCountryCurrency } from "../../../utils/function/currencies";
import { calRate, groupNumbersWithCommas } from "../../../utils/function/dateRangeTransfer";
import { isFiniteNumber } from "../../../utils/function/numbers";

import MDButton from "../../../components/MDButton";

const columns = [
    { Header: "Name", accessor: "keywordText", align: "left" },
    { Header: "optimizing", accessor: "optimizing", align: "center" },
    { Header: "Impressions", accessor: "impressions", align: "right" },
    { Header: "CTR", accessor: "ctr", align: "right" },
    { Header: "Clicks", accessor: "clicks", align: "right" },
    { Header: "Conv.Rate", accessor: "cr", align: "right" },
    { Header: "Cost", accessor: "costs", align: "right", sorting: true },
    { Header: "Avg.CPC", accessor: "avgCpc", align: "right" },
    { Header: "ACOS", accessor: "acos", align: "right" },
    { Header: "Purchases", accessor: "purchases", align: "right" },
    { Header: "Sales", accessor: "sales", align: "right" },
    { Header: "Target.ACOS", accessor: "targetAcos", align: "center" },
];

function AllKeywords(props) {
    const localStorageAccount = JSON.parse(localStorage.getItem('account'));

    const loading = useSelector((state) => state.adGroups.optimizeLoading);
    const accounts = useSelector((state) => state.accounts.accounts);

    const dispatch = useDispatch();

    const { changeAcosList, acosLevelList, keywordData, compareKeywordData, optimizingData, subtype, compareOption } = props;

    const [targetAcos, setTargetAcos] = useState(compareKeywordData);
    const [countryCode, setCountryCode] = useState('us');

    const cookies = new Cookies();

    const [paidAccounts, setPaidAccounts] = useState([]);

    useEffect(() => {
        // !Subscriptions

        dispatch(getSubscriptions()).then((res) => {
            if (res.data.length > 0) {
                const idList = [];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < res.data.length; i++) {
                    idList.push(res.data[i].profileId)
                }
                setPaidAccounts(idList);
            }
        });
    }, []);

    const handleOptimizeKeyword = (keyword, profileId, event) => {
        if (paidAccounts.length > 0 && paidAccounts.includes(compareKeywordData[0]?.profileId)) {
            dispatch(setOptimizeLoading());
            dispatch(setOptimizeKeyword(keyword, profileId, event.target.checked)).then(() => {
                window.location.reload();
            });
        } else {
            // eslint-disable-next-line no-lonely-if
            if (event.target.checked) {
                cookies.set('optimizeId', compareKeywordData[0]?.profileId, { path: '/checkout' });
                cookies.set('optimizeValue', event.target.checked, { path: '/checkout' });
                cookies.set('optimizeType', "account", { path: '/checkout' });
                window.location.href = "/checkout";
            } else {
                dispatch(setOptimizeLoading());
                dispatch(setOptimizeKeyword(keyword, profileId, event.target.checked)).then(() => {
                    window.location.reload();
                });
            }
        }
    };

    const handleUpdateKeyword = (keywords) => {
        const itemTargetAcos = targetAcos[keywords.idx];

        if (isFiniteNumber(itemTargetAcos) && parseFloat(itemTargetAcos) >= 0) {
            dispatch(saveKeywordTargetAcos({ [keywords.idx]: itemTargetAcos })).then((res) => {
                dispatch(setAlert("Save Target Acos Success"));
            });
        }
    };

    // let showData = structuredClone(compareKeywordData);
    let showData = JSON.parse(JSON.stringify(compareKeywordData));

    // eslint-disable-next-line array-callback-return
    showData.map((keyword) => {
        const obj = keywordData.find(o => o.idx === keyword.idx);
        let tAcos = {};
        tAcos = optimizingData.find(o => o.keywordId === keyword.idx);

        if (obj) {
            keyword.impressions_check = calRate(obj.impressions, keyword.impressions);
            keyword.impressions = obj.impressions;
            keyword.ctr_check = calRate(obj.ctr, keyword.ctr);
            keyword.ctr = obj.ctr;
            keyword.clicks_check = calRate(obj.clicks, keyword.clicks);
            keyword.clicks = obj.clicks;
            keyword.cr_check = calRate(obj.cr, keyword.cr);
            keyword.cr = obj.cr;
            keyword.costs_check = calRate(obj.costs, keyword.costs);
            keyword.costs = obj.costs;
            keyword.avgCpc_check = calRate(obj.avgCpc, keyword.avgCpc);
            keyword.avgCpc = obj.avgCpc;
            keyword.acos_check = calRate(obj.acos, keyword.acos);
            keyword.acos = obj.acos;
            keyword.purchases_check = calRate(obj.purchases, keyword.purchases);
            keyword.purchases = obj.purchases;
            keyword.sales_check = calRate(obj.sales, keyword.sales);
            keyword.sales = obj.sales;
        }
        keyword.targetAcos = tAcos.targetAcos || 0;
        keyword.optimizing = tAcos.optimizing;
    })

    showData = groupNumbersWithCommas(showData, ['name', 'idx', 'profileId', 'keywordText', 'name', 'optimizing']);
    const rows = showData ? showData.map((keyword) => ({
        keywordText: (
            <MDTypography component="a" href="#" variant="button" color="blue" fontWeight="medium">
                {keyword.keywordText}
            </MDTypography>
        ),

        impressions: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {keyword.impressions}<br />{keyword.impressions_check}
            </MDTypography>
        ),

        ctr: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {keyword.ctr}%<br />{keyword.ctr_check}
            </MDTypography>
        ),

        clicks: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {keyword.clicks}<br />{keyword.clicks_check}
            </MDTypography>
        ),

        cr: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {keyword.cr}%<br />{keyword.cr_check}
            </MDTypography>
        ),

        costs: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {getCountryCurrency(countryCode)}{keyword.costs ? `${keyword.costs}` : 0}<br />{keyword.costs_check}
            </MDTypography>
        ),

        avgCpc: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {keyword.avgCpc}<br />{keyword.avgCpc_check}
            </MDTypography>
        ),

        acos: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {keyword.acos && isFiniteNumber(keyword.acos) ? keyword.acos : '–'}<br />
                {(() => {
                    if (compareOption) {
                        if (keyword.acos_check && isFiniteNumber(keyword.acos_check?.slice(1, -1)))
                            return keyword.acos_check;

                        return '–';
                    }

                    return '';
                })()}
            </MDTypography>
        ),

        purchases: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {keyword.purchases}<br />{keyword.purchases_check}
            </MDTypography>
        ),

        sales: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {getCountryCurrency(countryCode)}{keyword.sales ? `${keyword.sales}` : 0}<br />{keyword.sales_check}
            </MDTypography>
        ),

        targetAcos: (
            <MDBox mt={0.5} style={{ display: "flex", flexDirection: "row" }}>
                <MDInput
                    style={{ padding: "6px 12px 0 0" }}
                    label="target acos (%)"
                    placeholder="Target ACOS: %"
                    size="small"
                    type="text"
                    defaultValue={`${keyword.targetAcos ? keyword.targetAcos : Math.round(keyword.acos)}`}
                    onChange={(e) => {
                        const val = e.target.value;
                        acosLevelList[`${keyword.idx}`] = val.replace(/[^\d.-]/g, "");
                        changeAcosList(acosLevelList)
                        setTargetAcos({ ...targetAcos, [keyword.idx]: acosLevelList[`${keyword.idx}`] });
                    }}
                />
                <MDBox mt={0.5}>
                    <MDButton
                        onClick={(e) => { handleUpdateKeyword(keyword, e) }}
                        variant="gradient"
                        color="blue"
                        disabled={loading}
                    >
                        Save
                    </MDButton>
                </MDBox>
            </MDBox>
        ),

        optimizing: (
            <MDBox mt={0.5}>
                <Switch
                    checked={keyword.optimizing}
                    onChange={(event) => handleOptimizeKeyword(keyword.idx, keyword.profileId, event)}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={loading}
                />
            </MDBox>
        ),
    })) : {};

    const sortedRows = rows.sort((a, b) => {
        const clicksA = parseInt(a.costs.props.children[1].replace(/,/g, ''), 10);
        const clicksB = parseInt(b.costs.props.children[1].replace(/,/g, ''), 10);
        return clicksB - clicksA;
    });

    return !sortedRows.length ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
            {loading && <CircularProgress color="info" />}
            {!loading && !keywordData.length && <>Keywords missing for this AdGroup</>}
        </MDBox>
    ) : (
        <MDBox pt={3}>
            <DataTable
                table={{ columns, rows: sortedRows }}
                isSorted
                entriesPerPage={false}
                showTotalEntries
                noEndBorder
            />
        </MDBox>
    );
}

export default AllKeywords;
