/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'universal-cookie';
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";

import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Footer from "../../examples/Footer";
import SubscriptionModalNew from "./components/subcriptionModalNew";

import {
  getPlans
} from "../../store/actions/paymentActions"

const CheckOutModal = (props) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();

  // eslint-disable-next-line react/prop-types
  const { description, checkoutInfo = {} } = props;
  const { accountId, optimizing } = checkoutInfo;

  // const accountId = ["undefined", "null"].includes(cookies.get("optimizeId")) ? JSON.parse(localStorage.getItem('account'))?.profileId : cookies.get("optimizeId");
  // const optimizing = cookies.get("optimizeValue");

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    dispatch(getPlans()).then((res) => {
      setPlans(res.data.data);
    })
  }, []);

  return (
    <MDBox mt={6} mb={3}>
      <Grid container spacing={3} justifyContent="center">
        {/* <Grid item xs={12} md={12} lg={1} /> */}
        <Grid item xs={12} md={12} lg={9}>

          <SubscriptionModalNew
            title="Subscription to Scalomatic"
            // description="To activate the optimization, you need to subscribe to Scalomatic. 
            //               The subscription is monthly. 
            //               You can cancel at any time. Would you like to proceed?"
            // description="You need to subscribe to this account to be able to see your data. Would you like to proceed?"
            // description="You need to subscribe to this account to be able to see your Ad Groups & Keywords data. The subscription is monthly. You can cancel at any time. Would you like to proceed?"
            description={description}
            plans={plans}
            accountId={accountId}
            optimizing={optimizing}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default CheckOutModal;
