// eslint-disable-next-line import/prefer-default-export
export const jsDateToDashedString = (date) => {
  if (!(date instanceof Date))
    return date;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}