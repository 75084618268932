/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";

export const WaitLoadingData = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <MDBox mt={6} mb={3}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8} height="80vh">
          {/* <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
            <CircularProgress color="info" size="4rem" />
          </MDBox> */}
          <MDProgress value={progress} variant="gradient" />
          <br />
          <div>Your accounts data is currently loading, please check back shortly. This takes about 3 hours, depending on the size of your account. This is a one time wait...</div>
        </Grid>
      </Grid>
    </MDBox>
  );
};