// User
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_VERIFICATION_REQUIRED = "LOGIN_VERIFICATION_REQUIRED";
export const SET_OPEN_VERIFICATION_MODAL = "SET_OPEN_VERIFICATION_MODAL";
export const RESET_MAIL_RESEND = "RESET_MAIL_RESEND";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const EMAIL_RESEND = "EMAIL_RESEND";
export const USER_LOADED = "USER_LOADED";
export const USER_ERROR = "USER_ERROR";
export const GET_USER_INFO = "GET_USER_INFO";
export const RESET_EMAIL_SENT = "RESET_EMAIL_SENT";
export const RESET_CODE_SUCCESS = "RESET_CODE_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_CODE_DISABLED = "RESET_CODE_DISABLED";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATED_PROFILE = "UPDATED_PROFILE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const CLEAR_USER_LOADING = "CLEAR_USER_LOADING";
export const GET_HOTEL_LOCATION = "GET_HOTEL_LOCATION";
export const USER_INFO_FAIL = "USER_INFO_FAIL";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const LINK_SENT = "LINK_SENT";
export const RESET_PASS_SUCCESSS = "RESET_PASS_SUCCESSS";

// Clearings
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_DATA = "CLEAR_DATA";

// Alert
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ALERT = "SET_ALERT";

// Date Range Picker
export const CHECK_RANGE = "CHECK_RANGE";
export const RANGE_FAIL = "RANGE_FAIL";

// Payment Subscription
export const PAYMENT_CONNECT = "PAYMENT_CONNECT";
export const PAYMENT_FAIL = "PAYMENT_FAIL";

// Accounts
export const CONNECT_ACCOUNT = "CONNECT_ACCOUNT";
export const ACCOUNTS_FAIL = "ACCOUNTS_FAIL";
export const UPDATE_ACCOUNT_SUBSCRIPTIONS_FAIL = "UPDATED_ACCOUNT_SUBSCRIPTIONS";
export const SET_ACCOUNTS_LOADING = "SET_ACCOUNTS_LOADING";
export const SET_ACCOUNTS_OPTIMIZE_LOADING = "SET_ACCOUNTS_OPTIMIZE_LOADING";
export const GET_ORG_ACCOUNTS = "GET_ORG_ACCOUNTS";
export const GET_DATA_READY = "GET_DATA_READY";
export const UPDATED_PROFILE_OPTIMIZE = "UPDATED_PROFILE_OPTIMIZE";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const UPDATED_TARGET_ACOS = "UPDATED_TARGET_ACOS";
export const UPDATED_ACCOUNT_SUBSCRIPTIONS = "UPDATED_ACCOUNT_SUBSCRIPTIONS";
export const GET_PROFILE = "GET_PROFILE";
export const SET_ACCOUNT = "SET_ACCOUNT";

// Campaigns
export const SET_CAMPAIGNS_LOADING = "SET_CAMPAIGNS_LOADING";
export const SET_CAMPAIGNS_OPTIMIZE_LOADING = "SET_CAMPAIGNS_OPTIMIZE_LOADING";
export const GET_ADGROUPS = "GET_ADGROUPS";
export const CAMPAIGNS_FAIL = "CAMPAIGNS_FAIL";
export const UPDATED_CAMPAIGN_OPTIMIZE = "UPDATED_CAMPAIGN_OPTIMIZE";
export const SET_CAMPAIGN = "SET_CAMPAIGN";

// AdGroups
export const GET_ADGROUPADS = "GET_ADGROUPADS";
export const ADGROUP_FAIL = "ADGROUP_FAIL";
export const SET_ADGROUP_LOADING = "SET_ADGROUP_LOADING";
export const SET_ADGROUP_OPTIMIZE_LOADING = "SET_ADGROUP_OPTIMIZE_LOADING";
export const UPDATED_ADGROUP_OPTIMIZE = "UPDATED_ADGROUP_OPTIMIZE";
export const UPDATED_KEYWORD_OPTIMIZE = "UPDATED_KEYWORD_OPTIMIZE";
export const SET_ADGROUP = "SET_ADGROUP";
