// import axios from "axios";
import axios from "../../api/axios";

export const post = (body, url, type, typeFail, headers) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      ...headers
    },
  };

  try {
    const res = await axios.post(`${url}`, body, config);
    dispatch({
      type,
      payload: res?.data,
    });

    return res
  } catch (err) {
    dispatch({
      type: typeFail,
      payload: err?.response?.data?.message,
    });

    throw err
  }
};

export const get = ({ url, params, type, typeFail, rest }) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}`, { params });

    dispatch({
      type,
      payload: res?.data,
      ...rest
    });

    return res
  } catch (err) {
    dispatch({
      type: typeFail,
      payload: err?.response?.data?.message,
    });

    throw err
  }
};

export const patch = (url, body, type, typeFail) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.patch(`${url}`, body, config);

    dispatch({
      type,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: typeFail,
      payload: err?.response?.data.message,
    });

    throw err;
  }
};

export const deleteById = (id, url, type, typeFail) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    await axios.delete(`${url}/${id}`, config);

    dispatch({
      type,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: typeFail,
      payload: err?.response?.data.message,
    });
  }
};
