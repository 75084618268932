/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
// import axios from "axios";

import { CLEAR_ERRORS, SET_ADGROUP_LOADING, SET_ADGROUP_OPTIMIZE_LOADING } from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Get Adgroup Ads and Keywordss
// export const getAdGroupsAds = (adGroupId) =>
//   factory.get({ url: `${URL}/adGroups/${adGroupId}`, type: "GET_ADGROUPADS", typeFail: "ADGROUP_FAIL" });

export const getAdGroupAds = ({ profileId, campaignId, adGroupId, adGroups }) => {
  return factory.get({
    url: `${URL}/adgroups/keywords?profileId=${profileId}&campaignId=${campaignId}&adGroupId=${adGroupId}`,
    type: "GET_ADGROUPADS",
    typeFail: "ADGROUP_FAIL",
    rest: {
      adGroup: adGroups?.find(adGroup => adGroup.adGroupId === adGroupId)
    }
  });
}

export const compareRangeData = (compareRangeConfiguration) => {
  return factory.get({ url: `${URL}/profiles/history`, params: compareRangeConfiguration, type: "COMPARE_RANGE", typeFail: "ADGROUP_FAIL" });
}

// Set AdGroup Optimize
export const setOptimizeAdGroup = (adgroupId, profileId, optimize) =>
  factory.patch(
    `${URL}/adgroups/optimize`,
    { optimizing: optimize, adGroupId: adgroupId, profileId },
    "UPDATED_ADGROUP_OPTIMIZE",
    "ADGROUP_FAIL"
  );
// Set keyword Optimize
export const setOptimizeKeyword = (keywordId, profileId, optimize) =>
  factory.patch(
    `${URL}/keywords-targets/optimize`,
    { optimizing: optimize, keywordId, profileId },
    "UPDATED_KEYWORD_OPTIMIZE",
    "ADGROUP_FAIL"
  );

export const saveAdGroupTargetAcos = (acosLevelList) => {
  const objEntries = Object.entries(acosLevelList);

  return factory.patch(
    `${URL}/adgroups/target-acos`,
    { adGroupId: objEntries[0][0], targetAcos: Number(objEntries[0][1]) },
    "UPDATED_TARGET_ACOS",
    "ACCOUNTS_FAIL"
  );
}

export const saveKeywordTargetAcos = (acosLevelList) => {
  const objEntries = Object.entries(acosLevelList);

  return factory.patch(
    `${URL}/keywords-targets/target-acos`,
    { keywordId: objEntries[0][0], targetAcos: Number(objEntries[0][1]) },
    "UPDATED_TARGET_ACOS",
    "ACCOUNTS_FAIL"
  );
}

export const setAdGroup = (adGroup) => ({ type: CLEAR_ERRORS, adGroup });

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading -- Used for login
export const setLoading = () => ({ type: SET_ADGROUP_LOADING });
export const setOptimizeLoading = () => ({ type: SET_ADGROUP_OPTIMIZE_LOADING });
