import { useEffect, useRef, useState } from "react";

/**
 *
 * @param {*} min
 * @param {*} sec
 * @returns [timer,resetTimer]
 * @returns timer: is the timer
 * @returns resetTimer: is the fuction to reset or start the timer and takes as params (minutes, seconds)
 */
export default function useTimer(min, sec) {
  const TimeRef = useRef(null);
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(`${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`);
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00");

    if (TimeRef.current) {
      clearInterval(TimeRef.current);
    }
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    TimeRef.current = id;
  };

  const getDeadTime = (m, s) => {
    const deadline = new Date();

    if (m > 0) {
      deadline.setMinutes(deadline.getMinutes() + m);
    }
    if (s > 0) {
      deadline.setSeconds(deadline.getSeconds() + s);
    }

    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime(min, sec));

    return () => clearInterval(TimeRef.current);
  }, []);

  const onClickReset = (m, s) => {
    clearTimer(getDeadTime(m, s));
  };

  return [timer, onClickReset];
}
