/* eslint-disable react/no-array-index-key */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from "react";
// @mui material components
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";

function Invoices(props) {
    const { loading, accounts, subscriptions, setShow, btnShow } = props;
    let { invoices } = props;

    const convertTime = (stamp) => {
        const date = new Date(parseInt(stamp, 10) * 1000);
        return date.toDateString();
    }

    const showAll = () => {
        if (btnShow === "Show All") {
            setShow("Invoices");
        }
        else {
            setShow("Ref");
        }
    }

    invoices = invoices?.map((inv) => {
        const subscription = subscriptions?.find(sub => inv.subscriptionId === sub.subscriptionId);
        const account = accounts?.find(acc => subscription?.profileId === acc.profileId);
        inv.account = account;

        return inv;
    }).filter(inv => inv.account);

    return (
        <Card sx={{ maxHeight: "335px", overflow: "scroll" }}>
            <MDBox pt={2} px={4} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                    Invoices
                </MDTypography>
                <MDButton variant="outlined" color="primary" size="small" onClick={showAll}>
                    {btnShow}
                </MDButton>
            </MDBox>
            <MDBox p={2} px={4}>
                {loading &&
                    <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                        <CircularProgress color="info" />
                    </MDBox>}
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {/* eslint-disable-next-line array-callback-return */}
                    {invoices.length > 0 && !loading ? invoices.map((invoice, idx) => (
                        <Invoice
                            key={`${idx}-${invoice.invoiceId}`}
                            invoice={invoice}
                            date={convertTime(invoice.created)}
                            id={invoice.number}
                            price={`$${invoice.amount_paid / 100}`}
                            url={invoice.invoice_pdf}
                        />
                    )) : !loading && <MDBox component="ul" display="flex" flexDirection="column" pb={6.25} m={0}>
                        Your Invoices List is Empty
                    </MDBox>}
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default Invoices;
