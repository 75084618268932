/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'universal-cookie';

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CheckOutModal from "layouts/checkOut/checkOutModal";

import setAlert from "store/actions/alertActions";

import {
    getSubscriptions,
} from "store/actions/accountActions";

import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCampaign, setOptimizeCampaign, setOptimizeLoading } from "store/actions/campaignActions";
import { calRate, groupNumbersWithCommas } from "../../../utils/function/dateRangeTransfer";

import { saveCampaignTargetAcos } from "../../../store/actions/campaignActions";
import { getCountryCurrency } from "../../../utils/function/currencies";
import { isFiniteNumber } from "../../../utils/function/numbers";

const columns = [
    { Header: "Name", accessor: "campaignName", align: "left" },
    { Header: "optimizing", accessor: "optimizing", align: "center" },
    { Header: "Impressions", accessor: "impressions", align: "right" },
    { Header: "CTR", accessor: "ctr", align: "right" },
    { Header: "Clicks", accessor: "clicks", align: "right" },
    { Header: "Conv.Rate", accessor: "cr", align: "right" },
    { Header: "Cost", accessor: "costs", align: "right", sorting: true },
    { Header: "Avg.CPC", accessor: "avgCpc", align: "right" },
    { Header: "ACOS", accessor: "acos", align: "right" },
    { Header: "Purchases", accessor: "purchases", align: "right" },
    { Header: "Sales", accessor: "sales", align: "right" },
    { Header: "Target.ACOS", accessor: "targetAcos", align: "center" },
];

function Campaign({ name, id, onClick, subscribed = false }) {
    return (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography
                onClick={onClick}
                component={Link}
                to={(() => {

                    if (!subscribed)
                        return null;

                    return `/campaigns/${id}`
                })()}
                variant="button"
                color="blue"
                fontWeight="medium"
                display="block"
            >
                {name}
            </MDTypography>
        </MDBox>
    );
}

function AllCampaigns(props) {
    const campaigns = useSelector((state) => state.campaigns.campaigns);
    const loading = useSelector((state) => state.accounts.loading);
    const optimizeLoading = useSelector((state) => state.campaigns.optimizeLoading);
    const account = useSelector((state) => state.accounts.account);
    const { countryCode = 'us' } = account || {};

    const { changeAcosList, acosLevelList, campaignData, compareCampaignData, optimizingData, compareOption } = props;
    const [targetAcos, setTargetAcos] = useState(compareCampaignData);

    const dispatch = useDispatch();
    const cookies = new Cookies();

    const [paidAccounts, setPaidAccounts] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [checkoutInfo, setCheckoutInfo] = useState({});

    useEffect(() => {
        // !Subscriptions

        dispatch(getSubscriptions()).then((res) => {
            if (res.data.length > 0) {
                const idList = [];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < res.data.length; i++) {
                    idList.push(res.data[i].profileId)
                }
                setPaidAccounts(idList);
            }
        });

        return () => {
            setPaidAccounts([]);
        };

    }, []);

    const handleUpdateCampaign = (campaign) => {
        const itemTargetAcos = targetAcos[campaign.idx];

        if (isFiniteNumber(itemTargetAcos) && parseFloat(itemTargetAcos) >= 0) {
            dispatch(saveCampaignTargetAcos({ [campaign.idx]: targetAcos[campaign.idx] })).then((res) => {
                dispatch(setAlert("Save Target Acos Success"));
            });
        }
    };

    const handleOptimizeCampaign = (campaign, profileId, event) => {
        if (paidAccounts.length > 0 && paidAccounts.includes(compareCampaignData[0]?.profileId)) {
            dispatch(setOptimizeLoading());
            dispatch(setOptimizeCampaign(campaign, profileId, event.target.checked)).then(() => {
                window.location.reload();
            });
        } else {
            // eslint-disable-next-line no-lonely-if
            if (event.target.checked) {
                cookies.set('optimizeId', compareCampaignData[0]?.profileId, { path: '/checkout' });
                cookies.set('optimizeValue', event.target.checked, { path: '/checkout' });
                cookies.set('optimizeType', "account", { path: '/checkout' });
                window.location.href = "/checkout";
            } else {
                dispatch(setOptimizeLoading());
                dispatch(setOptimizeCampaign(campaign, profileId, event.target.checked)).then(() => {
                    window.location.reload();
                });
            }
        }
    };

    // let showData = structuredClone(compareCampaignData);
    let showData = JSON.parse(JSON.stringify(compareCampaignData));

    // eslint-disable-next-line array-callback-return
    showData.map((campaign) => {
        const obj = campaignData.find(o => o.idx === campaign.idx);
        const tAcos = optimizingData.find(o => o.campaignId === campaign.idx);
        if (obj) {
            campaign.impressions_check = calRate(obj.impressions, campaign.impressions);
            campaign.impressions = obj.impressions;
            campaign.ctr_check = calRate(obj.ctr, campaign.ctr);
            campaign.ctr = obj.ctr;
            campaign.clicks_check = calRate(obj.clicks, campaign.clicks);
            campaign.clicks = obj.clicks;
            campaign.cr_check = calRate(obj.cr, campaign.cr);
            campaign.cr = obj.cr;
            campaign.costs_check = calRate(obj.costs, campaign.costs);
            campaign.costs = obj.costs;
            campaign.avgCpc_check = calRate(obj.avgCpc, campaign.avgCpc);
            campaign.avgCpc = obj.avgCpc;
            campaign.acos_check = calRate(obj.acos, campaign.acos);
            campaign.acos = obj.acos;
            campaign.purchases_check = calRate(obj.purchases, campaign.purchases);
            campaign.purchases = obj.purchases;
            campaign.sales_check = calRate(obj.sales, campaign.sales);
            campaign.sales = obj.sales;
        }
        // campaign.targetAcos = tAcos?.targetAcos ? Object.values(tAcos.targetAcos)[0] : null;
        campaign.targetAcos = tAcos?.targetAcos || 0;
        campaign.optimizing = tAcos?.optimizing;
        campaign.countryCode = countryCode;
    })

    showData = groupNumbersWithCommas(showData, ['name', 'idx', 'profileId', 'campaignName', 'countryCode', 'name', 'optimizing']);
    // eslint-disable-next-line arrow-body-style
    const rows = showData ? showData.map((campaign) => {
        return {
            campaignName: (
                <Campaign
                    name={campaign.campaignName}
                    id={campaign.idx}
                    subscribed={paidAccounts.includes(campaign.profileId)}
                    onClick={() => {
                        if (!paidAccounts.includes(campaign.profileId)) {
                            setModalOpen(true);
                            setCheckoutInfo({ accountId: campaign.profileId, optimizing: true });
                        } else {
                            const matchedCampaign = campaigns?.find(arrCampaign => arrCampaign.campaignId === campaign.idx) || JSON.parse(localStorage.getItem('campaign'));
                            dispatch(setCampaign(matchedCampaign));
                            localStorage.setItem('campaign', JSON.stringify(matchedCampaign));
                        }
                    }}
                />
            ),

            optimizing: (
                <MDBox mt={0.5}>
                    <Switch
                        checked={campaign.optimizing}
                        onChange={(event) => {
                            handleOptimizeCampaign(campaign.idx, campaign.profileId, event)
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={optimizeLoading}
                    />
                </MDBox>
            ),

            impressions: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {campaign.impressions}<br />{campaign.impressions_check}
                </MDTypography>
            ),

            ctr: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {campaign.ctr}%<br />{campaign.ctr_check}
                </MDTypography>
            ),

            clicks: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {campaign.clicks}<br />{campaign.clicks_check}
                </MDTypography>
            ),

            cr: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {campaign.cr}%<br />{campaign.cr_check}
                </MDTypography>
            ),

            costs: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {getCountryCurrency(countryCode)}{campaign.costs && isFiniteNumber(campaign.costs) ? `${campaign.costs}` : 0}<br />{campaign.costs_check}
                </MDTypography>
            ),

            avgCpc: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {campaign.avgCpc}<br />{campaign.avgCpc_check}
                </MDTypography>
            ),

            acos: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {campaign.acos && isFiniteNumber(campaign.acos) ? campaign.acos : '–'}<br />
                    {(() => {
                        if (compareOption) {
                            if (campaign.acos_check && isFiniteNumber(campaign.acos_check?.slice(1, -1)))
                                return campaign.acos_check;

                            return '–';
                        }

                        return '';
                    })()}
                </MDTypography>
            ),

            purchases: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {campaign.purchases}<br />{campaign.purchases_check}
                </MDTypography>
            ),

            sales: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                    {getCountryCurrency(countryCode)}{campaign.sales && isFiniteNumber(campaign.sales) ? `${campaign.sales}` : 0}<br />{campaign.sales_check}
                </MDTypography>
            ),

            targetAcos: (
                <MDBox mt={0.5} style={{ display: "flex", flexDirection: "row" }}>
                    <MDInput
                        style={{ padding: "6px 12px 0 0" }}
                        label="target acos (%)"
                        placeholder="Target ACOS: %"
                        size="small"
                        type="text"
                        defaultValue={`${campaign.targetAcos ? campaign.targetAcos : Math.round(campaign.acos)}`}
                        onChange={(e) => {
                            const val = e.target.value;
                            acosLevelList[`${campaign.idx}`] = val.replace(/[^\d.-]/g, "");
                            changeAcosList(acosLevelList);
                            setTargetAcos({ ...targetAcos, [campaign.idx]: acosLevelList[`${campaign.idx}`] });
                        }}
                    />
                    <MDBox mt={0.5}>
                        <MDButton
                            onClick={(e) => { handleUpdateCampaign(campaign, e) }}
                            variant="gradient"
                            color="blue"
                            disabled={loading}
                        >
                            Save
                        </MDButton>
                    </MDBox>

                </MDBox>
            ),
        };
    }) : {};

    const sortedRows = rows.sort((a, b) => {
        const clicksA = parseInt(a.costs.props.children[1].replace(/,/g, ''), 10);
        const clicksB = parseInt(b.costs.props.children[1].replace(/,/g, ''), 10);
        return clicksB - clicksA;
    });

    return (
        <MDBox pt={3}>
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: 1000,
                    bgcolor: 'background.paper',
                    borderRadius: "0.8rem",
                    boxShadow: 24,
                    p: 4,
                }}>
                    <CheckOutModal checkoutInfo={checkoutInfo} description="You need to subscribe to this account to be able to see your Ad Groups & Keywords data. The subscription is monthly. You can cancel at any time. Would you like to proceed?" />
                </Box>
            </Modal>
            {campaigns.length ? (
                <DataTable
                    // table={{ columns, rows }}
                    table={{ columns, rows: sortedRows }}
                    isSorted
                    entriesPerPage={false}
                    showTotalEntries
                    noEndBorder
                />
            ) : null}
        </MDBox>
    );
}

export default AllCampaigns;