/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Switch from "@mui/material/Switch";
import { getAdGroupAds, saveKeywordTargetAcos, setLoading, compareRangeData } from "store/actions/adGroupActions";
import Calendar from "../../components/Calendar";
// import { compareRangeData } from "../../store/actions/accountActions";
import setAlert from "../../store/actions/alertActions";
import { filterKeywordData } from "../../utils/function/dateRangeTransfer";
import { jsDateToDashedString } from "../../utils/function/dates";
import AllKeywords from "./components/allKeywords";

const AdGroupDetails = () => {
    const localStorageAccount = JSON.parse(localStorage.getItem('account')) || {};
    const localStorageCampaign = JSON.parse(localStorage.getItem('campaign')) || {};
    const localStorageAdGroup = JSON.parse(localStorage.getItem('adGroup')) || {};

    const { adGroupId } = useParams();
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.adGroups.loading);
    const [dataLoading, setDataLoading] = useState(true);
    const account = useSelector((state) => state.accounts.account);
    const campaign = useSelector((state) => state.campaigns.campaign);
    // const adGroup = useSelector((state) => state.adGroups.adGroup);

    const [keywords, setKeywords] = useState([]);
    const [adGroup, setAdGroup] = useState({});

    const [acosLevelList, setAcosLevelList] = React.useState({});

    const [keywordData, setKeywordData] = useState([]);
    const [newCompare, setNewCompare] = useState(0);
    const [compareKeywordData, setCompareKeywordData] = useState([]);

    const [dateRange, setDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date()
    });
    const [compareDateRange, setCompareDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 30))
    });

    const [compareStyle, setCompareStyle] = useState({ display: "none" })
    const [compareOption, setCompareOption] = useState(false);

    const changeDateRange = (data) => {
        setDataLoading(true);
        setDateRange(data);

        if (compareOption && account && campaign) {
            dispatch(compareRangeData({
                ...data,
                entities: ['KEYWORD'],
                profileId: account.profileId,
                campaignId: campaign.campaignId,
                adGroupId
            })).then((resp) => {
                setKeywordData(filterKeywordData(resp.data.keywordsHistory, keywords));
                setDataLoading(false);
            });
        }
    }

    const changeCompareDateRange = (data) => {
        setDataLoading(true);
        setCompareDateRange(data);
        dispatch(setAlert("Data Loading, Please wait"));

        if (account && campaign) {
            dispatch(compareRangeData({
                ...data,
                entities: ['KEYWORD'],
                profileId: account.profileId,
                campaignId: campaign.campaignId,
                adGroupId
            })).then((resp) => {
                setNewCompare(newCompare + 1);
                setCompareKeywordData(filterKeywordData(resp.data.keywordsHistory, keywords));
                setDataLoading(false);
            });
        }
    }

    const handleCompareDR = (event) => {
        setCompareOption(event.target.checked)
        if (event.target.checked) {
            setCompareStyle({ display: "block" })
        }
        else {
            setCompareStyle({ display: "none" })
        }
    }

    useEffect(() => {
        setDataLoading(true);
        dispatch(setLoading());
        // dispatch(getAdGroupAds(adGroupId || localStorageAdGroup.adGroupId)).then((res) => {
        dispatch(getAdGroupAds({
            profileId: account?.profileId || localStorageAccount.profileId,
            campaignId: campaign?.campaignId || localStorageCampaign.campaignId,
            adGroupId
        })).then((res) => {
            const respKeywords = res.data;
            setKeywords(respKeywords);

            dispatch(setAlert("Data Loading, Please wait"));

            if (compareOption && account && campaign) {
                dispatch(compareRangeData({
                    ...dateRange,
                    entities: ['KEYWORD'],
                    profileId: account?.profileId || localStorageAccount.profileId,
                    campaignId: campaign?.campaignId || localStorageCampaign.campaignId,
                    adGroupId
                })).then((resp) => {
                    // eslint-disable-next-line no-lonely-if
                    setKeywordData(filterKeywordData(resp.data.keywordsHistory, respKeywords));
                    // dispatch(setAlert("Data Loading, Please wait"));
                });
            } else {
                setKeywordData([]);
            }

            dispatch(compareRangeData({
                ...compareDateRange,
                entities: ['KEYWORD'],
                profileId: account?.profileId || localStorageAccount.profileId,
                campaignId: campaign?.campaignId || localStorageCampaign.campaignId,
                adGroupId
            })).then((resp) => {
                // eslint-disable-next-line no-lonely-if
                setCompareKeywordData(filterKeywordData(resp.data.keywordsHistory, respKeywords));
                setDataLoading(false);
            });
        });
    }, [compareOption, account, campaign]);

    const changeAcosList = (data) => {
        setAcosLevelList(data);
    }

    const handleSaveAcos = () => {
        // dispatch(setLoading(true));
        dispatch(saveKeywordTargetAcos(acosLevelList)).then((res) => {
            setKeywords(res.data.data.keywords);
            dispatch(setAlert("Save Target Acos Success"));
            // window.location.reload();
        });
    };

    const styles = {
        container: {
            display: "flex",
            padding: "5px",
            backgroundColor: "white !important",
            borderRadius: "0.75rem",
            marginLeft: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem"
        },
        dateRange: {
            backgroundColor: "white !important",
            padding: "15px",
            minWidth: "320px"
        },
        compareToggle: {
            backgroundColor: "white !important",
            paddingTop: "15px",
            minWidth: "70px",
        },
        compareRange: {
            backgroundColor: "white !important",
            marginLeft: "-15px",
            padding: "15px",
            minWidth: "350px"
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar customTitle="Keyword Details" />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date Range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} height="80vh">
                        {loading || dataLoading ? (
                            <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                <CircularProgress color="info" />
                            </MDBox>
                        ) : (
                            <Card id="all-campaigns">
                                <MDBox
                                    pt={2}
                                    px={2}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Keywords
                                    </MDTypography>
                                </MDBox>
                                <AllKeywords
                                    compareOption={compareOption}
                                    changeAcosList={changeAcosList}
                                    acosLevelList={acosLevelList}
                                    keywordData={keywordData}
                                    compareKeywordData={compareKeywordData}
                                    optimizingData={keywords}
                                    subtype={adGroup.subtype}
                                />
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout>
    );
};

export default AdGroupDetails;
