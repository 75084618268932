/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getCampaignAdgroups, setLoading } from "store/actions/campaignActions";
import Switch from "@mui/material/Switch";
import AllAdGroups from "../adGroups/components/allAdGroups";
import setAlert from "../../store/actions/alertActions";
import { compareRangeData } from "../../store/actions/accountActions";
import { filterAdGroupData, readAdGroupData } from "../../utils/function/dateRangeTransfer";
import MDButton from "../../components/MDButton";
import { saveAdGroupTargetAcos } from "../../store/actions/adGroupActions";
import Calendar from "../../components/Calendar";
import { jsDateToDashedString } from "../../utils/function/dates";

const CampaignDetails = () => {
    const { campaignId } = useParams();

    const dispatch = useDispatch();

    const localStorageAccount = JSON.parse(localStorage.getItem('account'));

    const account = useSelector((state) => state.accounts.account);
    const loading = useSelector((state) => state.campaigns.loading);

    const [adGroups, setAdGroups] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    const [acosLevelList, setAcosLevelList] = React.useState({});

    const [adGroupData, setAdGroupData] = useState([]);
    const [newCompare, setNewCompare] = useState(0);
    const [compareAdGroupData, setCompareAdGroupData] = useState([]);

    const [dateRange, setDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date()
    });
    const [compareDateRange, setCompareDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 30))
    });

    const [compareStyle, setCompareStyle] = useState({ display: "none" })
    const [compareOption, setCompareOption] = useState(false);

    const changeDateRange = (data) => {
        setDateRange(data);

        if (compareOption && adGroups && account) {
            setDataLoading(true);
            dispatch(compareRangeData({ ...data, entities: ['ADGROUP'], profileId: account?.profile, campaignId }))
                .then((resp) => {
                    setAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, adGroups))
                    setDataLoading(false);
                });
        }
    }

    const changeCompareDateRange = (data) => {
        setDataLoading(true);
        setCompareDateRange(data);
        dispatch(setAlert("Data Loading, Please wait"));
        dispatch(compareRangeData({ ...data, entities: ['ADGROUP'], profileId: account?.profile, campaignId }))
            .then((resp) => {
                setNewCompare(newCompare + 1);
                setCompareAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, adGroups))
                setDataLoading(false);

            });
    }

    const handleCompareDR = (event) => {
        setCompareOption(event.target.checked)
        if (event.target.checked) {
            setCompareStyle({ display: "block" })
        }
        else {
            setCompareStyle({ display: "none" })
        }
    }

    useEffect(() => {
        setDataLoading(true);
        dispatch(setLoading());
        const profileId = account?.profileId || localStorageAccount.profileId;
        dispatch(getCampaignAdgroups({
            profileId,
            campaignId,
        })).then((res) => {
            const campaignAdGroups = res.data;
            setAdGroups(campaignAdGroups);
            dispatch(setAlert("Data Loading, Please wait"));

            if (compareOption) {
                dispatch(compareRangeData({ ...dateRange, entities: ['ADGROUP'], profileId, campaignId })).then((resp) => {
                    setAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, campaignAdGroups))
                });
            } else {
                setAdGroupData([]);
            }

            dispatch(compareRangeData({ ...compareDateRange, entities: ['ADGROUP'], profileId, campaignId })).then((resp) => {
                setCompareAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, campaignAdGroups))
                setDataLoading(false);
            });
        });
    }, [campaignId, account, compareOption]);

    const changeAcosList = (data) => {
        setAcosLevelList(data);
    }

    const handleSaveAcos = () => {
        dispatch(saveAdGroupTargetAcos(acosLevelList)).then((res) => {
            setAdGroups(res.data);
            dispatch(setAlert("Save Target Acos Success"));
        });
    };

    const styles = {
        container: {
            display: "flex",
            padding: "5px",
            backgroundColor: "white !important",
            borderRadius: "0.75rem",
            marginLeft: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem"
        },
        dateRange: {
            backgroundColor: "white !important",
            padding: "15px",
            minWidth: "320px"
        },
        compareToggle: {
            backgroundColor: "white !important",
            paddingTop: "15px",
            minWidth: "70px",
        },
        compareRange: {
            backgroundColor: "white !important",
            marginLeft: "-15px",
            padding: "15px",
            minWidth: "350px"
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar customTitle="AdGroup Details" />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date Range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} height="80vh">
                        <Card id="all-campaigns">
                            <MDBox
                                pt={2}
                                px={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" fontWeight="medium">
                                    Ad Groups
                                </MDTypography>
                            </MDBox>

                            <AllAdGroups
                                compareOption={compareOption}
                                changeAcosList={changeAcosList}
                                acosLevelList={acosLevelList}
                                adGroupData={adGroupData}
                                compareAdGroupData={compareAdGroupData}
                                optimizingData={adGroups}
                            />
                            {dataLoading && <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                {loading || dataLoading && <CircularProgress color="info" />}
                            </MDBox>}
                            {!loading && !adGroups.length && <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                AdGroups missing for this Campaign
                            </MDBox>}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout >
    );
};

export default CampaignDetails;
