/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'universal-cookie';
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";

import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Footer from "../../examples/Footer";
import SubscriptionModal from "./components/subcriptionModal";

import {
    getPlans
} from "../../store/actions/paymentActions"

const CheckOut = () => {
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const accountId = ["undefined", "null"].includes(cookies.get("optimizeId")) ? JSON.parse(localStorage.getItem('account'))?.profileId : cookies.get("optimizeId");
    const optimizing = cookies.get("optimizeValue");

    const [plans, setPlans] = useState([]);

    useEffect(() => {
        dispatch(getPlans()).then((res) => {
            setPlans(res.data.data);
        })
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar customTitle="checkoutTest" />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid item xs={12} md={12} lg={1} />
                    <Grid item xs={12} md={12} lg={9}>
                        <SubscriptionModal
                            title="Subscription to Scalomatic"
                            description="To activate the optimization, you need to subscribe to Scalomatic. 
                            The subscription is monthly. 
                            You can cancel at any time. Would you like to proceed?"
                            plans={plans}
                            accountId={accountId}
                            optimizing={optimizing}
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default CheckOut;
