/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Formik (NOT USING FORM!!)
import { Formik } from "formik";
import * as Yup from "yup";
// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButtonLoading from "components/MDButtonLoading";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import setAlert from "store/actions/alertActions";
import { signup, clearErrors, setLoading, resetMailResend } from "store/actions/userActions";
import ConfirmEmailDialog from "../components/ConfirmEmailDialog";

const SignupSchema = Yup.object().shape({
    email: Yup.string().required("Email is required!").email("Invalid email"),
    name: Yup.string().required("Please enter your name!"),
    organizationName: Yup.string().required(
        "Please choose an Organization name to create a new organization or contact your Organization primary user to invite you to an existing one!"
    ),

    password: Yup.string()
        .required("Please enter a password!")
        .min(8, "Password must be at least 6 characters long!"),
    passwordConfirm: Yup.string()
        .required("Please confirm your password!")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    terms: Yup.boolean().oneOf([true], "Please agree to the terms and conditions!"),
});

function Cover() {
    const dispatch = useDispatch();
    const [tempWait, setTempWait] = useState(false);
    const error = useSelector((state) => state.users.error);
    const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
    const loading = useSelector((state) => state.users.loading);
    const user = useSelector((state) => state.users.user);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (error) {
            dispatch(setAlert(error, "error"));
            dispatch(clearErrors());
        }
    }, [error, setAlert]);
    useEffect(() => {
        if (user && tempWait && !error) {
            dispatch(resetMailResend());
            setOpen(true);
        }
    }, [user]);
    if (isAuthenticated) {
        return <Navigate to="/accounts" />;
    }
    return (
        <CoverLayout image={bgImage}>
            <Card>
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        password: "",
                        passwordConfirm: "",
                        organizationName: "",
                        terms: false,
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            setSubmitting(false);
                            dispatch(setLoading());
                            setTempWait(true);
                            dispatch(signup(values));
                        }, 500);
                    }}
                >
                    {({
                        setFieldValue,
                        values,
                        submitForm,
                        // isSubmitting,
                        touched,
                        errors,
                    }) => (
                        <>
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="success"
                                mx={2}
                                mt={-3}
                                p={3}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Join us today
                                </MDTypography>
                                <MDTypography display="block" variant="button" color="white" my={1}>
                                    Enter your email and password to register
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="text"
                                            id="name"
                                            name="name"
                                            label="Name"
                                            variant="standard"
                                            error={!!(errors.name && touched.name)}
                                            onChange={(event) => setFieldValue("name", event.target.value)}
                                            helperText={touched.name && errors.name}
                                            fullWidth
                                        />
                                    </MDBox>

                                    <MDBox mb={2}>
                                        <MDInput
                                            type="email"
                                            label="Email"
                                            variant="standard"
                                            id="email"
                                            name="email"
                                            error={!!(errors.email && touched.email)}
                                            onChange={(event) => setFieldValue("email", event.target.value)}
                                            helperText={touched.email && errors.email}
                                            fullWidth
                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="password"
                                            label="Password"
                                            variant="standard"
                                            id="password"
                                            name="password"
                                            error={!!(errors.password && touched.password)}
                                            onChange={(event) => setFieldValue("password", event.target.value)}
                                            helperText={touched.password && errors.password}
                                            fullWidth
                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="password"
                                            label="Confirm Password"
                                            variant="standard"
                                            id="passwordConfirm"
                                            name="passwordConfirm"
                                            error={!!(errors.passwordConfirm && touched.passwordConfirm)}
                                            onChange={(event) => setFieldValue("passwordConfirm", event.target.value)}
                                            helperText={touched.passwordConfirm && errors.passwordConfirm}
                                            fullWidth
                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="text"
                                            label="Organization"
                                            variant="standard"
                                            id="organizationName"
                                            name="organizationName"
                                            error={!!(errors.organizationName && touched.organizationName)}
                                            onChange={(event) => setFieldValue("organizationName", event.target.value)}
                                            helperText={touched.organizationName && errors.organizationName}
                                            fullWidth
                                        />
                                    </MDBox>
                                    <MDBox display="flex" alignItems="center" ml={-1}>
                                        <Checkbox
                                            checked={values.terms}
                                            onChange={(event) => setFieldValue("terms", event.target.checked)}
                                            error={touched.terms && errors.terms}
                                        />
                                        <MDTypography
                                            variant="button"
                                            fontWeight="regular"
                                            color="text"
                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                        >
                                            &nbsp;&nbsp;I agree the&nbsp;
                                        </MDTypography>
                                        <MDTypography
                                            component="a"
                                            href="#"
                                            variant="button"
                                            fontWeight="bold"
                                            color="info"
                                            textGradient
                                        >
                                            Terms and Conditions
                                        </MDTypography>
                                    </MDBox>

                                    <MDTypography variant="caption" fontWeight="light" color="error" mt={1}>
                                        {touched.terms && errors.terms ? errors.terms : " "}
                                    </MDTypography>

                                    <MDBox mt={4} mb={1}>
                                        <MDButtonLoading
                                            loading={loading}
                                            loadingPosition="start"
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            startIcon={<></>}
                                            variant="gradient"
                                            color="info"
                                            fullWidth
                                            onClick={submitForm}
                                        >
                                            sign up
                                        </MDButtonLoading>
                                    </MDBox>
                                    <MDBox mt={3} mb={1} textAlign="center">
                                        <MDTypography variant="button" color="text">
                                            Already have an account?{" "}
                                            <MDTypography
                                                component={Link}
                                                to="/authentication/sign-in"
                                                variant="button"
                                                color="info"
                                                fontWeight="medium"
                                                textGradient
                                                disabled={loading}
                                            >
                                                <div style={{ textDecoration: "underline !important" }}>Sign In</div>
                                            </MDTypography>
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </>
                    )}
                </Formik>
                <ConfirmEmailDialog open={open} setOpen={setOpen} />
            </Card>
        </CoverLayout>
    );
}

export default Cover;
