/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Bill from "layouts/billing/components/Bill";
import MDButton from "../../../../components/MDButton";

function BillingInformation(props) {
    const { subscriptions, infoUrl } = props;

    const editStripe = () => {
        window.location.href = infoUrl;
    }

    return (
        <Card id="delete-account">
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Billing Information
                </MDTypography>
                <MDButton variant="outlined" color="primary" size="small" onClick={editStripe}>
                    Edit All
                </MDButton>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {/* eslint-disable-next-line react/prop-types */}
                    {subscriptions.length > 0 ? subscriptions.map((subscription) => (
                        <Bill
                            name={subscription.customerName}
                            email={subscription.customerEmail}
                            amount={subscription.amount}
                            url={subscription.infoUrl}
                        />
                    )): null}
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default BillingInformation;
