/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'universal-cookie';
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";

import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Footer from "../../examples/Footer";
import SubscriptionModal from "./components/subcriptionModal";

import {
    getPlans
} from "../../store/actions/paymentActions"

const Unsubscribe = () => {
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const accountId = cookies.get("optimizeId");
    const optimizing = cookies.get("optimizeValue");
    const unsubscribe = cookies.get("unsubscribe");

    const [plans, setPlans] = useState([]);

    useEffect(() => {
        dispatch(getPlans()).then((res) => {
            setPlans(res.data);
        })
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar customTitle="checkoutTest" />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid item xs={12} md={12} lg={1} />
                    <Grid item xs={12} md={12} lg={9}>
                        <SubscriptionModal
                            title="Unsubscribe your Scalomatic account"
                            description="You are about to deactivate your subscription. You will still have access to the service until the end of the subscription. After that, your account will not be optimized and your ACOS might increase. Are you sure you would like to proceed?"
                            plans={plans}
                            accountId={accountId}
                            optimizing={optimizing}
                            unsubscribe={unsubscribe}
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default Unsubscribe;
