/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'universal-cookie';

import DataTable from "examples/Tables/DataTable";
// import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import {
    saveAccountTargetAcos,
    setOptimizeLoading,
    setOptimizeProfile
} from "store/actions/accountActions";
import setAlert from "store/actions/alertActions";

import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";
import { calRate, groupNumbersWithCommas } from "../../../utils/function/dateRangeTransfer";

import { setAccount } from "../../../store/actions/accountActions";
import { getCountryCurrency } from "../../../utils/function/currencies";
import { isFiniteNumber } from "../../../utils/function/numbers";

const columns = [
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "optimizing", accessor: "optimizing", align: "center" },
    { Header: "Impressions", accessor: "impressions", align: "right" },
    { Header: "CTR", accessor: "ctr", align: "right" },
    { Header: "Clicks", accessor: "clicks", align: "right" },
    { Header: "Conv.Rate", accessor: "cr", align: "right" },
    { Header: "Cost", accessor: "costs", align: "right", sorting: true },
    { Header: "Avg.CPC", accessor: "avgCpc", align: "right" },
    { Header: "ACOS", accessor: "acos", align: "right" },
    { Header: "Purchases", accessor: "purchases", align: "right" },
    { Header: "Sales", accessor: "sales", align: "right" },
    { Header: "Target.ACOS", accessor: "targetAcos", align: "center" },
];

function Account({ name, profileId, onClick }) {
    return (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography
                component={Link}
                to={`/accounts/${profileId}`}
                variant="button"
                color="blue"
                fontWeight="medium"
                display="block"
                onClick={onClick}
            >
                {name}
            </MDTypography>
        </MDBox>
    );
}

const allAccounts = (props) => {
    const { changeAcosList, acosLevelList, accountData, compareAccountData, optimizingData, compareOption, paidSubscriptions, paidSubscriptionsLoading } = props;
    const [targetAcos, setTargetAcos] = useState(compareAccountData);
    const accounts = useSelector((state) => state.accounts.accounts);

    const loading = useSelector((state) => state.accounts.loading);
    const optimizeLoading = useSelector((state) => state.accounts.optimizeLoading);

    const dispatch = useDispatch();
    const cookies = new Cookies();

    const handleOptimizeProfile = (profile, event) => {
        if (paidSubscriptions.length > 0 && paidSubscriptions.map(el => el.profileId).includes(profile)) {
            dispatch(setOptimizeLoading());
            dispatch(setOptimizeProfile(profile, event.target.checked)).then(() => {
                window.location.reload();
            });
        } else {
            // eslint-disable-next-line no-lonely-if
            if (event.target.checked) {
                cookies.set('optimizeId', profile, { path: '/checkout' });
                cookies.set('optimizeValue', event.target.checked, { path: '/checkout' });
                cookies.set('optimizeType', "account", { path: '/checkout' });
                window.location.href = "/checkout";
            }
            else {
                dispatch(setOptimizeLoading());
                dispatch(setOptimizeProfile(profile, event.target.checked)).then(() => {
                    window.location.reload();
                });
            }
        }
    };

    const handleUpdateAccount = (account) => {
        const itemTargetAcos = targetAcos[account.idx];

        if (isFiniteNumber(itemTargetAcos) && parseFloat(itemTargetAcos) >= 0) {
            dispatch(saveAccountTargetAcos({ [account.idx]: itemTargetAcos })).then((res) => {
                dispatch(setAlert("Save Target Acos Success"));
            });
        }
    };

    let showData = JSON.parse(JSON.stringify(compareAccountData));

    // eslint-disable-next-line array-callback-return
    showData.map((account) => {
        const obj = accountData.find(o => o.idx === account.idx);
        const optimizingAccount = optimizingData.find(o => o.profileId === account.idx);

        if (obj) {
            account.impressions_check = calRate(obj.impressions, account.impressions);
            account.impressions = obj.impressions;
            account.ctr_check = calRate(obj.ctr, account.ctr);
            account.ctr = obj.ctr;
            account.clicks_check = calRate(obj.clicks, account.clicks);
            account.clicks = obj.clicks;
            account.cr_check = calRate(obj.cr, account.cr);
            account.cr = obj.cr;
            account.costs_check = calRate(obj.costs, account.costs);
            account.costs = obj.costs;
            account.avgCpc_check = calRate(obj.avgCpc, account.avgCpc);
            account.avgCpc = obj.avgCpc;
            account.acos_check = calRate(obj.acos, account.acos);
            account.acos = obj.acos;
            account.purchases_check = calRate(obj.purchases, account.purchases);
            account.purchases = obj.purchases;
            account.sales_check = calRate(obj.sales, account.sales);
            account.sales = obj.sales;
        }

        account.targetAcos = optimizingAccount.targetAcos || 0;
        account.optimizing = optimizingAccount.optimizing || paidSubscriptions.find((el) => {

            if (el.profileId === optimizingAccount.profileId && !optimizingAccount.optimizing && (Date.now() - new Date(el.createdAt).getTime() < 1 * 3600000)) {

                const { createdAt, profileId } = JSON.parse(localStorage.getItem(`optimize-${el.profileId}`)) || {};

                if (!createdAt) {

                    localStorage.setItem(`optimize-${el.profileId}`, JSON.stringify({ profileId: el.profileId, createdAt: Date.now(), gaPurchase: true }));

                    window.gtag("event", "purchase", {
                        transaction_id: Date.now(),
                        hostname: window.location.hostname,
                        accountName: optimizingAccount.accountName,
                        profileId,
                        subscriptionId: el.subscriptionId,
                        value: process.env.NODE_ENV !== 'PRODUCTION' ? 0.50 : 99,
                        currency: optimizingAccount.currencyCode || 'USD',
                        label: "Subscription purchase event",
                        coupon: JSON.stringify({
                            transaction_id: Date.now(),
                            hostname: window.location.hostname,
                            accountName: optimizingAccount.accountName,
                            profileId,
                            subscriptionId: el.subscriptionId,
                            value: process.env.NODE_ENV !== 'PRODUCTION' ? 0.50 : 99,
                            currency: optimizingAccount.currencyCode || 'USD',
                            label: "Subscription purchase event",
                        })
                    });

                    dispatch(setOptimizeLoading());
                    dispatch(setOptimizeProfile(el.profileId, true)).then(() => {
                        window.location.reload();
                    });

                    return true;
                }

                return false;
            }

            return false;
        });
        // account.optimizing = optimizingAccount.optimizing || paidSubscriptions.find(el => el === optimizingAccount.profileId);
        account.countryCode = optimizingAccount.countryCode;
    })
    showData = groupNumbersWithCommas(showData, ['name', 'idx', 'profileId', 'countryCode', 'accountName', 'optimizing']);

    const rows = showData ? showData.map((account) => ({
        name: (
            <Account
                name={account.name}
                profileId={account.idx}
                onClick={() => {
                    const matchedAccount = accounts?.find(acc => acc.profileId === account.idx) || JSON.parse(localStorage.getItem('account'));
                    dispatch(setAccount(matchedAccount));
                    localStorage.setItem('account', JSON.stringify(matchedAccount));
                }} />
        ),

        impressions: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {account.impressions && isFiniteNumber(account.impressions) ? account.impressions : 0}<br />{account.impressions_check}
            </MDTypography>
        ),

        ctr: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {account.ctr && isFiniteNumber(account.ctr) ? account.ctr : 0}%<br />{account.ctr_check}
            </MDTypography>
        ),

        clicks: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {account.clicks && isFiniteNumber(account.clicks) ? account.clicks : 0}<br />{account.clicks_check}
            </MDTypography>
        ),

        cr: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {account.cr && isFiniteNumber(account.cr) ? account.cr : 0}%<br />{account.cr_check}
            </MDTypography>
        ),

        costs: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {getCountryCurrency(account.countryCode)}{account.costs && isFiniteNumber(account.costs) ? `${account.costs}` : 0}<br />{account.costs_check}
            </MDTypography>
        ),

        avgCpc: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {account.avgCpc && isFiniteNumber(account.avgCpc) ? account.avgCpc : 0}<br />{account.avgCpc_check}
            </MDTypography>
        ),

        acos: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {account.acos && isFiniteNumber(account.acos) ? `${account.acos}%` : '–'}
                <br />
                {(() => {
                    if (compareOption) {
                        if (account.acos_check && isFiniteNumber(account.acos_check?.slice(1, -1)))
                            return account.acos_check;

                        return '–';
                    }

                    return '';
                })()}
            </MDTypography>
        ),

        purchases: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {account.purchases && isFiniteNumber(account.purchases) ? account.purchases : 0}<br />{account.purchases_check}
            </MDTypography>
        ),

        sales: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {getCountryCurrency(account.countryCode)}{account.sales && isFiniteNumber(account.sales) ? `${account.sales}` : 0}<br />{account.sales_check}
            </MDTypography>
        ),

        targetAcos: (
            <MDBox mt={0.5} style={{ display: "flex", flexDirection: "row" }}>
                <MDInput
                    style={{ padding: "6px 12px 0 0" }}
                    label="target acos (%)"
                    placeholder="Target ACOS: %"
                    size="small"
                    type="text"
                    value={`${targetAcos[account.idx] || account.targetAcos}`}
                    onChange={(e) => {
                        const val = e.target.value;
                        acosLevelList[`${account.idx}`] = val.replace(/[^\d.-]/g, "");
                        changeAcosList(acosLevelList);
                        setTargetAcos({ ...targetAcos, [account.idx]: acosLevelList[`${account.idx}`] });
                    }}
                />
                <MDBox mt={0.5}>
                    <MDButton
                        onClick={(e) => { handleUpdateAccount(account, e) }}
                        variant="gradient"
                        color="blue"
                        disabled={loading}
                    >
                        Save
                    </MDButton>
                </MDBox>
            </MDBox>
        ),

        optimizing: (
            <MDBox mt={0.5}>
                <Switch
                    checked={account.optimizing}
                    onChange={(event) => handleOptimizeProfile(account.idx, event)}
                    inputProps={{ "aria-label": "controlled" }}
                    // eslint-disable-next-line eqeqeq
                    disabled={optimizeLoading || paidSubscriptionsLoading}
                />
            </MDBox>
        ),
    })) : {};

    const sortedRows = rows.sort((a, b) => {
        const clicksA = parseInt(a.costs.props.children[1].replace(/,/g, ''), 10);
        const clicksB = parseInt(b.costs.props.children[1].replace(/,/g, ''), 10);
        return clicksB - clicksA;
    });

    return (
        <MDBox pt={3}>
            {accounts.length > 0 && !loading ? (
                <DataTable
                    table={{ columns, rows: sortedRows }}
                    isSorted
                    entriesPerPage={false}
                    showTotalEntries
                    noEndBorder
                />
            ) : null}
        </MDBox>
    );
};

export default allAccounts;
