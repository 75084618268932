/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import axios from "../../api/axios";

import { CLEAR_ERRORS, SET_ACCOUNTS_LOADING, SET_ACCOUNTS_OPTIMIZE_LOADING, SET_ACCOUNT } from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Connect Account
export const connectUs = (code) => {
  return factory.post({ code, region: 'us' }, `${URL}/accounts/connect`, "CONNECT_ACCOUNT", "ACCOUNTS_FAIL");

}

export const connectEu = (code) => {
  return factory.post({ code, region: 'eu' }, `${URL}/accounts/connect`, "CONNECT_ACCOUNT", "ACCOUNTS_FAIL");
}


export const connectFe = (code) => {
  return factory.post({ code, region: 'fe' }, `${URL}/accounts/connect`, "CONNECT_ACCOUNT", "ACCOUNTS_FAIL");
}

// Check subscription for the user
// export const getSubscriptions = () =>
//   factory.post(
//     {},
//     `${URL}/accounts/check-subscription`,
//     "UPDATED_TARGET_ACOS",
//     "ACCOUNTS_FAIL"
//   );

export const getSubscriptions = () =>
  factory.get(
    {
      url: `${URL}/accounts/subscriptions/paid`,
      type: "UPDATED_TARGET_ACOS",
      typeFail: "ACCOUNTS_FAIL"
    }
  );

// Update account subscriptions and account optimization
// export const updateAccountSubscriptions = () =>
//   factory.post(
//     {},
//     `${URL}/accounts/update-account-subscriptions`,
//     "UPDATED_ACCOUNT_SUBSCRIPTIONS",
//     "UPDATE_ACCOUNT_SUBSCRIPTIONS_FAIL",
//   );

export const updateAccountSubscriptions = () =>
  factory.patch(
    `${URL}/accounts/subscriptions`,
    {},
    "UPDATED_ACCOUNT_SUBSCRIPTIONS",
    "UPDATE_ACCOUNT_SUBSCRIPTIONS_FAIL",
  );

// Update Target ACOS for Account
export const saveAccountTargetAcos = (acosLevelList) => {
  const objEntries = Object.entries(acosLevelList);

  return factory.patch(
    `${URL}/profiles/target-acos`,
    { profileId: objEntries[0][0], targetAcos: Number(objEntries[0][1]) },
    "UPDATED_TARGET_ACOS",
    "ACCOUNTS_FAIL"
  );
}

export const compareRangeData = (compareRangeConfiguration) => {
  return factory.get({ url: `${URL}/profiles/history`, params: compareRangeConfiguration, type: "CHECK_RANGE", typeFail: "RANGE_FAIL" });
}

export const getOrgAccounts = () =>
  factory.get({ url: `${URL}/profiles/org`, type: "GET_ORG_ACCOUNTS", typeFail: "ACCOUNTS_FAIL" });

export const getDataReady = () =>
  factory.get({ url: `${URL}/accounts/check-ready`, type: "GET_DATA_READY", typeFail: "ACCOUNTS_FAIL" });

export const getAccountById = (profileId, accounts) => {
  return factory.get({ url: `${URL}/profiles/campaigns?profileId=${profileId}`, type: "GET_PROFILE", typeFail: "ACCOUNTS_FAIL", rest: { account: accounts?.find(account => account.profileId === profileId) } });
}

// Set Profile Optimize
export const setOptimizeProfile = (profile, optimize) =>
  factory.patch(
    `${URL}/profiles/optimize`,
    { profileId: profile, optimizing: optimize },
    "UPDATED_PROFILE_OPTIMIZE",
    "ACCOUNTS_FAIL"
  );

export const setAccount = (account) => ({ type: SET_ACCOUNT, account });

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading -- Used for login
export const setLoading = () => ({ type: SET_ACCOUNTS_LOADING });
export const setOptimizeLoading = () => ({ type: SET_ACCOUNTS_OPTIMIZE_LOADING });
