/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from "react";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import Link from "@mui/material/Link";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { cancelSubscription } from "store/actions/paymentActions";
import { useDispatch } from "react-redux";
import Cookies from 'universal-cookie';

function SubscriptionModal(props) {
    const { title, description, plans, accountId, optimizing, unsubscribe = false } = props;
    const dispatch = useDispatch();
    const cookies = new Cookies();

    return (
        <Card>
            <MDBox p={3}>
                <MDTypography display="inline" variant="h3" textTransform="capitalize" fontWeight="bold">
                    {title}
                </MDTypography>
                <MDBox mt={2} mb={3}>
                    <MDTypography variant="body2" component="p" color="text">
                        {description}
                    </MDTypography>
                </MDBox>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid item xs={12} md={12} lg={2} />
                    <Grid item xs={12} md={12} lg={4} />
                    <Grid item xs={12} md={12} lg={1} />
                    <Grid item xs={12} md={6} lg={2}>
                        <Link href='/accounts'>
                            <MDButton type="submit" color="warning">
                                No, Cancel
                            </MDButton>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                        {
                            (() => {
                                if (unsubscribe) {
                                    return (
                                        <form
                                            action={`${process.env.REACT_APP_CLIENT_HOST}/accounts`}
                                        >
                                            <input name="accountId" type="hidden" value={accountId} />
                                            <input name="optimizing" type="hidden" value={optimizing} />
                                            <MDButton type="submit" color="info" onClick={() => {
                                                dispatch(cancelSubscription(accountId)).then(() => {
                                                    window.location.href = '/accounts';
                                                });
                                            }}>
                                                Yes, Proceed
                                            </MDButton>
                                        </form>
                                    );
                                }

                                return (<form
                                    action={`${process.env.REACT_APP_API_HOST}/subscriptions/checkout-session`}
                                    method="POST"
                                >
                                    <input name="accountId" type="hidden" value={accountId} />
                                    <input name="optimizing" type="hidden" value={optimizing === 'true' ? true : false} />

                                    <MDButton type="submit" color="info">
                                        Yes, Proceed
                                    </MDButton>
                                </form>);
                            })()
                        }
                    </Grid>
                </Grid>
            </MDBox>
        </Card >
    );
}

export default SubscriptionModal;
