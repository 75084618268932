/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { OutlinedInput, Typography, Box, Button } from "@mui/material";

import WbAutoIcon from "@mui/icons-material/WbAuto";

import { resendEmail, confirmEmail, setLoading } from "store/actions/userActions";
import useTimer from "../../../../utils/hooks/useTimer";
// import setAlert from "store/actions/alertActions";

function ConfirmEmailDialog({ open, setOpen }) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.users.loading);
  const user = useSelector((state) => state.users.user);
  const handleClose = () => {
    setOpen(false);
  };
  // useStates
  const [code, setCode] = useState("");

  const [codeTimer, resetCodeTimer] = useTimer(10, 0);
  /* resetResendTimer(minutes,seconds) */
  const [resendTimer, resetResendTimer] = useTimer(0, 30);
  const mailResend = useSelector((state) => state.users.mailResend);

  const checkSubmit = () => {
    if (code.length) {
      dispatch(setLoading());
      dispatch(confirmEmail({ code, email: user?.email }));
    }
  };
  const resendCode = () => {
    setCode("");
    if (mailResend < 5) {
      dispatch(resendEmail(user.email));
      resetCodeTimer(10, 0);
      resetResendTimer(0, 30);
    }
  };

  // const Transition = React.forwardRef((props, ref) => (
  //   <Slide direction="up" ref={ref} {...props} />
  // ));

  return (
    <Dialog
      open={open}
      // TransitionComponent={Transition}
      maxWidth="lg"
      keepMounted
      // fullScreen={fullScreen}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {/* <div style={{ width: "100%", textAlign: "center", position: "relative" }}>
        <div
          style={{
            width: "100px",
            height: "100px",
            backgroundColor: "#BFFF38",
            borderRadius: "50%",
            textAlign: "center",
            position: "absolute",
            left: "40%",
            top: "20px",
            paddingTop: "6px",
          }}
        >
          <WbAutoIcon alt="Character icon" />
        </div>
      </div> */}
      <DialogTitle
        sx={{
          textAlign: "center",
          paddingBottom: "48px",
          paddingTop: "124px",
        }}
      >
        <Typography variant="headline1_400" color="secondary.900">
          Please Verify Your Email
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "start", padding: "3em" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item sm={12}>
            <Typography variant="bodyS_400" color="secondary.500" sx={{ pt: "48px", pb: "24px" }}>
              By signing up, I agree to Company&apos;s <a href="/terms">Terms</a> and{" "}
              <a href="/terms">Privacy Conditions</a>
            </Typography>

            <Grid container sx={{ paddingTop: "24px" }}>
              <Grid item sm={12}>
                <Box>
                  <Typography
                    variant="bodyS_400"
                    color="secondary.500"
                    sx={{ pt: "48px", pb: "24px" }}
                  >
                    Verification code <br />
                  </Typography>
                  {/* <InputLabel
                          variant='outlined'
                          error={errors.email && touched.email ? true : false}
                        >
                          Email
                        </InputLabel> */}
                  <OutlinedInput
                    placeholder="Verification code"
                    variant="outlined"
                    type="number"
                    id="code"
                    size="small"
                    sx={{ width: "100%", marginTop: "8px" }}
                    // error={errors.email && touched.email ? true : false}
                    onChange={(event) => setCode(event.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} sx={{ marginTop: "24px" }}>
                {/* <Typography
                  variant="bodyS_400"
                  color="secondary.500"
                  marginBottom="24px"
                  sx={{ pt: "48px", pb: "24px" }}
                >
                  {codeTimer === "00:00"
                    ? "The code expired"
                    : `The code will expire in ${codeTimer}`}
                </Typography> */}

                {/* <Button
                  variant="primaryB"
                  sx={{ width: "100%" }}
                  size="small"
                  disabled={resendTimer !== "00:00" || mailResend > 4}
                  onClick={resendCode}
                >
                  {resendTimer !== "00:00"
                    ? resendTimer
                    : mailResend > 4
                      ? "Try again later"
                      : "Resend code "}
                </Button> */}

                <Button
                  variant="primaryA"
                  sx={{ width: "100%", fontSize: "15px" }}
                  size="large"
                  disabled={loading}
                  onClick={checkSubmit}
                >
                  {loading ? "Verifying..." : "Verify"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions>
          <Button sx={{ color: "#5F7D71", marginTop: "24px" }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

// Typechecking props of the ConfirmEmailDialog
ConfirmEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ConfirmEmailDialog;
