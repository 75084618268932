/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";

// react-router-dom components
import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import setAlert from "store/actions/alertActions";
import { setLoading, clearErrors, resetPass } from "store/actions/userActions";

function Cover() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.users.loading);
  const error = useSelector((state) => state.users.error);
  const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
  const [password, setPass] = useState("");
  const [validation, setValid] = useState("");
  // Pull Token from URL
  const { token } = useParams();

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert, clearErrors]);

  const setConfirm = (e) => {
    if (e.target.value !== password) {
      setValid("Password does not match");
    } else {
      setValid("");
    }
  };
  const handleSetPass = (e) => {
    e.preventDefault();
    if (e.target.value.length < 8) {
      setValid("Password must be at least 8 characters");
    } else {
      setValid("");
      setPass(e.target.value);
    }
  };

  const submitReset = () => {
    if (validation === "") {
      dispatch(setLoading());
      dispatch(resetPass(password, token)).then(res => {
        window.location.href = '/authentication/sign-in';
      });
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/accounts" />;
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset your Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                onChange={handleSetPass}
              />
            </MDBox>
            <MDBox mb={24}>
              <MDInput
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
                onChange={setConfirm}
              />
              <MDTypography variant="caption" color="error">
                {validation}
              </MDTypography>
            </MDBox>

            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={submitReset}
                disabled={loading}
              >
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
