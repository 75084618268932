/* eslint-disable arrow-body-style */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import { retrieveSession, checkWholeSub } from "../../store/actions/paymentActions";

function Billing() {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const [subscriptions, setSubscriptions] = useState([])
    const [accounts, setAccounts] = useState([])
    const [invoices, setInvoices] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [customerInfo, setCustomerInfo] = useState({})
    const [showingInfo, setShowingInfo] = useState("Ref")

    useEffect(() => {
        dispatch(retrieveSession()).then((res) => {
            setSubscriptions(res.data?.subscriptions || []);
            setAccounts(res.data?.profiles || []);
            setInvoices(res.data?.invoices?.reverse() || []);
            setPaymentMethods(res.data?.paymentMethods || []);
            setCustomerInfo(res.data?.customer || {});
            dispatch(checkWholeSub()).then((resp) => {
                setSubscriptions(resp.data);
                setLoading(false);
            });
        })
    }, []);

    useEffect(() => {
        if (accounts.length && subscriptions.length) {
            const accsWithSubs = accounts.map(account => {
                account.subscription = subscriptions.find(o => o.profileId === account.profileId);

                return account;
            });

            const tier1 = accsWithSubs.filter(el => el.subscription?.status === "active" && el.subscription?.paymentStatus === "paid" && el.subscription?.cancelled === false);
            const tier2 = accsWithSubs.filter(el => el.status === "active" && el.subscription?.paymentStatus === "paid" && el.subscription?.cancelled === true);
            const tier3 = accsWithSubs.filter(el => el.subscription && ![...tier1, ...tier2].find(compare => el.profileId === compare.profileId));
            const tier4 = accsWithSubs.filter(el => !el.subscription);

            setAccounts([...tier1, ...tier2, ...tier3, ...tier4]);
        }
    }, [subscriptions]);

    return (
        <DashboardLayout>
            <DashboardNavbar absolute isMini />
            {(() => {
                switch (showingInfo) {
                    case 'Ref':
                        return (
                            <MDBox mt={8}>
                                <MDBox mb={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={8}>
                                            <PaymentMethod
                                                loading={loading}
                                                paymentMethods={paymentMethods}
                                                infoUrl={subscriptions?.length > 0 ? subscriptions[0].infoUrl : "/billing"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Invoices
                                                loading={loading}
                                                subscriptions={subscriptions}
                                                accounts={accounts}
                                                invoices={invoices.filter(invoice => invoice?.account_name && Number(invoice.created) > new Date().getTime() / 1000 - 31536000)}
                                                setShow={setShowingInfo}
                                                btnShow="Show All"
                                            />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox mb={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Transactions
                                                loading={loading}
                                                accounts={accounts?.length > 5 ? accounts.slice(0, 5) : accounts}
                                                subscriptions={subscriptions}
                                                infoUrl={subscriptions?.length > 0 ? subscriptions[0].infoUrl : "/billing"}
                                                setShow={setShowingInfo}
                                                btnShow="Show All"
                                            />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </MDBox>);
                    case 'Subscriptions':
                        return (
                            <MDBox mt={8}>
                                <MDBox mb={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={8}>
                                            <Transactions
                                                loading={loading}
                                                accounts={accounts}
                                                subscriptions={subscriptions}
                                                infoUrl={subscriptions?.length > 0 ? subscriptions[0].infoUrl : "/billing"}
                                                setShow={setShowingInfo}
                                                btnShow="Back"
                                            />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </MDBox>);
                    case 'Invoices':
                        return (
                            <MDBox mt={8}>
                                <MDBox mb={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={8}>
                                            <Invoices
                                                loading={loading}
                                                subscriptions={subscriptions}
                                                accounts={accounts}
                                                invoices={invoices}
                                                setShow={setShowingInfo}
                                                btnShow="Back"
                                            />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </MDBox>);
                    default:
                        return null;
                }
            })()}
            <Footer />
        </DashboardLayout>
    );
}

export default Billing;
