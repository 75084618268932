/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useDispatch, useSelector } from "react-redux";
import setAlert from "store/actions/alertActions";
import {
    clearErrors,
    getOrgAccounts,
    getAccountById
} from "store/actions/accountActions";
import {
    setLoading,
    compareRangeData,
    saveCampaignTargetAcos
} from "store/actions/campaignActions";
import Switch from "@mui/material/Switch";
import AllCampaigns from "./components/allCampaigns";
import MDButton from "../../components/MDButton";
import Calendar from "../../components/Calendar";
import { filterCampaignData } from "../../utils/function/dateRangeTransfer";
import { jsDateToDashedString } from "../../utils/function/dates";

const Campaigns = () => {
    const dispatch = useDispatch();
    const error = useSelector((state) => state.accounts.error);
    const loading = useSelector((state) => state.accounts.loading);
    const campaignsLoading = useSelector((state) => state.campaigns.loading);
    const accounts = useSelector((state) => state.accounts.accounts);
    const account = useSelector((state) => state.accounts.account);

    const [accountValue, setAccountValue] = useState(null);
    const [mappedAccounts, setMappedAccounts] = useState([]);

    const mapAccounts = () => {
        return accounts.map((newaccount) => {
            setMappedAccounts((prevState) => [
                ...prevState,
                {
                    label: `${newaccount.accountName} - ${newaccount.countryCode}`,
                    value: newaccount.profileId,
                },
            ].sort((a, b) => a.label > b.label ? 1 : -1));

            return account;
        });
    };


    useEffect(() => {
        if (error) {
            dispatch(setAlert(error, "error"));
            dispatch(clearErrors());
        }
    }, [error, setAlert]);

    useEffect(() => {
        // dispatch(setLoading(true));
        dispatch(getOrgAccounts());
    }, []);

    useEffect(() => {
        if (accounts.length > 0 && mappedAccounts.length === 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < accounts.length; i++) {
                accounts[i].name = `${accounts[i].accountName} - ${accounts[i].countryCode}`;
            }
            mapAccounts(accounts);
        }
    }, [accounts, mappedAccounts]);
    useEffect(() => {
        if (account) {
            setAccountValue({ label: `${account.accountName} - ${account.countryCode}`, value: account.profileId });
        }
    }, [account]);

    const [campaigns, setCampaigns] = useState([]);
    const [acosLevelList, setAcosLevelList] = React.useState({});

    const [campaignData, setCampaignData] = useState([]);
    const [newCompare, setNewCompare] = useState(0);
    const [compareCampaignData, setCompareCampaignData] = useState([]);

    const [dateRange, setDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date()
    });
    const [compareDateRange, setCompareDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 30))
    });

    const [compareStyle, setCompareStyle] = useState({ display: "none" })
    const [compareOption, setCompareOption] = useState(false);

    const changeDateRange = (data) => {

        setDateRange(data);

        if (compareOption && account) {
            dispatch(compareRangeData({ ...data, entities: ['CAMPAIGN'], profileId: account?.profileId })).then((resp) => {
                setCampaignData(filterCampaignData(resp.data.campaignsHistory, campaigns))
            });
        }
    }

    const changeCompareDateRange = (data) => {
        setCompareDateRange(data);
        dispatch(setAlert("Data Loading, Please wait"));

        if (account) {
            dispatch(compareRangeData({ ...data, entities: ['CAMPAIGN'], profileId: account?.profileId })).then((resp) => {
                setNewCompare(newCompare + 1);
                setCompareCampaignData(filterCampaignData(resp.data.campaignsHistory, campaigns))
            });
        }
    }

    const handleCompareDR = (event) => {
        setCompareOption(event.target.checked)
        if (event.target.checked) {
            setCompareStyle({ display: "block" })
        } else {
            setCompareStyle({ display: "none" })
        }
    }

    useEffect(() => {
        dispatch(setLoading(true));
        if (account) {
            dispatch(getAccountById(account.profileId, accounts)).then((res) => {
                const effectCampaigns = res.data;
                setCampaigns(effectCampaigns);

                dispatch(setAlert("Data Loading, Please wait"));

                if (compareOption) {
                    dispatch(compareRangeData({ ...dateRange, entities: ['CAMPAIGN'], profileId: account?.profileId })).then((resp) => {
                        setCampaignData(filterCampaignData(resp.data.campaignsHistory, effectCampaigns.length ? effectCampaigns : campaigns))
                    });
                } else {
                    setCampaignData([]);
                }

                dispatch(compareRangeData({ ...compareDateRange, entities: ['CAMPAIGN'], profileId: account?.profileId })).then((resp) => {
                    setNewCompare(newCompare + 1);

                    setCompareCampaignData(filterCampaignData(resp.data.campaignsHistory, effectCampaigns.length ? effectCampaigns : campaigns))
                });
            });
        }
    }, [compareOption, account]);

    function handleChange(e, changedvalue) {
        dispatch(setLoading(true));

        if (!changedvalue) {
            setAccountValue(null);
        } else {
            setAccountValue(changedvalue);
            const profileId = changedvalue.value;
            dispatch(getAccountById(profileId, accounts)).then((res) => {
                const respCampaigns = res.data;
                setCampaigns(respCampaigns);
                dispatch(setAlert("Data Loading, Please wait"));

                if (compareOption) {
                    dispatch(compareRangeData({ ...dateRange, entities: ['CAMPAIGN'], profileId })).then((resp) => {
                        setCampaignData(filterCampaignData(resp.data.campaignsHistory, respCampaigns.length ? respCampaigns : campaigns))
                    });
                }

                dispatch(compareRangeData({ ...compareDateRange, entities: ['CAMPAIGN'], profileId })).then((resp) => {
                    setNewCompare(newCompare + 1);
                    setCompareCampaignData(filterCampaignData(resp.data.campaignsHistory, respCampaigns.length ? respCampaigns : campaigns))
                });
            });
        }
    }

    const changeAcosList = (data) => {
        setAcosLevelList(data);
    }

    const handleSaveAcos = () => {
        dispatch(saveCampaignTargetAcos(acosLevelList)).then((res) => {
            setCampaigns(res.data);
            dispatch(setAlert("Save Target Acos Success"));
            // window.location.reload();
        });
    };

    const styles = {
        container: {
            display: "flex",
            padding: "5px",
            backgroundColor: "white !important",
            borderRadius: "0.75rem",
            marginLeft: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem"
        },
        dateRange: {
            backgroundColor: "white !important",
            padding: "15px",
            minWidth: "320px"
        },
        compareToggle: {
            backgroundColor: "white !important",
            paddingTop: "15px",
            minWidth: "70px",
        },
        compareRange: {
            backgroundColor: "white !important",
            marginLeft: "-15px",
            padding: "15px",
            minWidth: "350px"
        }
    }

    return (account ?
        <DashboardLayout>
            <DashboardNavbar customTitle={account?.name} />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date Range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} height="80vh">
                        <Card id="delete-account">
                            <MDBox
                                pb={2}
                                pt={2}
                                px={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" fontWeight="medium">
                                    Campaigns
                                </MDTypography>
                                <MDBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Autocomplete
                                        value={accountValue}
                                        getOptionLabel={(option) => option.label}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={handleChange}
                                        id="controllable-states-demo"
                                        options={mappedAccounts}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Account" variant="standard" />
                                        )}
                                        isOptionEqualToValue={(option, newvalue) => option.value === newvalue.value}
                                    />
                                </MDBox>
                            </MDBox>
                            {!campaignsLoading && !campaigns.length &&
                                <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                    Campaigns missing for this account
                                </MDBox>}
                            {campaignsLoading || !compareCampaignData.length && campaigns.length ? (
                                <>
                                    <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                        Select Account
                                    </MDBox>
                                    <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                        <CircularProgress color="info" />
                                    </MDBox>
                                </>
                            ) : (
                                <AllCampaigns
                                    compareOption={compareOption}
                                    changeAcosList={changeAcosList}
                                    acosLevelList={acosLevelList}
                                    campaignData={campaignData}
                                    compareCampaignData={compareCampaignData}
                                    optimizingData={campaigns}
                                />
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout >
        : <DashboardLayout>
            <DashboardNavbar customTitle={account?.name} />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date Range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} height="80vh">
                        <Card id="delete-account">
                            <MDBox
                                pb={2}
                                pt={2}
                                px={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" fontWeight="medium">
                                    Campaigns
                                </MDTypography>
                                <MDBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Autocomplete
                                        value={accountValue}
                                        getOptionLabel={(option) => option.label}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={handleChange}
                                        id="controllable-states-demo"
                                        options={mappedAccounts}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Account" variant="standard" />
                                        )}
                                        isOptionEqualToValue={(option, newvalue) => option.value === newvalue.value}
                                    />
                                </MDBox>
                            </MDBox>
                            {(campaignsLoading || !accountValue) && (
                                <>
                                    <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                        Select Account
                                    </MDBox>
                                    <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                        <CircularProgress color="info" />
                                    </MDBox>
                                </>

                            )}
                            {!campaignsLoading && !campaigns.length &&
                                <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                    Campaigns missing for this account
                                </MDBox>}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >);
};

export default Campaigns;
