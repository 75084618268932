// import axios from "axios";
import axios from "../../api/axios";

import {
  CLEAR_ERRORS,
  SET_USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  RESET_MAIL_RESEND,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_VERIFICATION_REQUIRED,
  LOGIN_FAIL,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Signup User
export const signup = (data) =>
  factory.post(data, `${URL}/auth/signup`, "SIGNUP_SUCCESS", "USER_ERROR");

// Logout
export const logout = () => async (dispatch) => {
  try {
    await axios.post(`${URL}/auth/logout`);
    dispatch({
      type: LOGOUT,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

// Login User
export const loginUser = (user) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`${URL}/auth/login`, user, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data, // User
    });
  } catch (err) {

    if (err.response?.status === 402) {
      dispatch({
        type: LOGIN_VERIFICATION_REQUIRED,
        payload: user.email, // continue here
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response?.data?.message || 'Something went wrong',
      });
      dispatch(logout());
    }
  }
};

// Check user used to get user info on first load
export const checkUser = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(`${URL}/users/account`, config);

    dispatch({
      type: USER_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// To reset the mailResend number
export const resetMailResend = () => ({ type: RESET_MAIL_RESEND });

// Confirm Email - Step 2 of signup (expect user and token)
export const confirmEmail = ({ code, email }) =>
  factory.patch(`${URL}/auth/confirm-email`, { code, email }, "EMAIL_VERIFIED", "USER_ERROR");

// To resend registraion email (crrently uses 6 digit code for app users)
export const resendEmail = (email) =>
  factory.post({ email }, `${URL}/users/resendEmail`, "EMAIL_RESEND", "USER_ERROR");

// Forget Password
export const forgotPass = (email) =>
  factory.post({ email }, `${URL}/auth/forgot-password`, "LINK_SENT", "USER_ERROR");

// Reset Password
export const resetPass = (newPass, token) =>
  factory.post(
    { password: newPass, passwordConfirm: newPass, passwordResetToken: token },
    `${URL}/auth/reset-password`,
    "RESET_PASS_SUCCESSS",
    "USER_ERROR"
  );

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading -- Used for login
export const setLoading = () => ({ type: SET_USER_LOADING });
