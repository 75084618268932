/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable no-alert */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'universal-cookie';
import { useDispatch } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { cancelSubscription } from "store/actions/paymentActions";
import { TroubleshootOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

function Transactions(props) {
    const { loading, accounts, subscriptions, infoUrl, setShow, btnShow } = props;

    const dispatch = useDispatch();
    const cookies = new Cookies();

    const subAction = (e) => {
        const accountStatus = e.target.innerText;
        const accountId = e.target.id;
        const account = accounts.find(o => o.profileId === accountId);

        const subscription = subscriptions.find(el => el.accountId === accountId);

        if (accountStatus === "UNSUBSCRIBE") {
            cookies.set('optimizeId', account.profileId, { path: '/unsubscribe' });
            cookies.set('optimizeValue', !account.optimizing, { path: '/unsubscribe' });
            cookies.set('optimizeType', "account", { path: '/unsubscribe' });
            cookies.set('unsubscribe', TroubleshootOutlined, { path: '/unsubscribe' });
            window.location.href = '/unsubscribe';

            // dispatch(cancelSubscription(account.profileId, !account.optimizing)).then(() => {
            //     window.location.href = '/unsubscribe';
            //     window.location.reload();
            // });
        }
        else if (accountStatus.includes("CANCEL")) {
            const text = "Do you want to renew your subscription?";
            if (window.confirm(text) === true) {
                window.location.href = infoUrl;
            }
        }
        else {
            cookies.set('optimizeId', account.profileId, { path: '/checkout' });
            cookies.set('optimizeValue', !account.optimizing, { path: '/checkout' });
            cookies.set('optimizeType', "account", { path: '/checkout' });
            window.location.href = "/checkout";
        }
    }

    const editStripe = () => {
        window.location.href = infoUrl;
    }

    const checkSubStatus = (account) => {
        const subscript = subscriptions.find(o => o.profileId === account.profileId);

        if (subscript) {
            if (subscript.status === "canceled") {
                return "Subscribe";
            }
            if (subscript.cancelled) {
                return `Cancels on ${subscript.periodEndDate}`;
            }
            if (subscript.paymentStatus === "paid") {
                return "Unsubscribe"
            }

            return "Subscribe"
        }

        return "Subscribe";
    }

    const checkSubColorStatus = (account) => {
        const subscript = subscriptions.find(o => o.profileId === account.profileId);

        if (subscript) {
            if (subscript.status === "canceled") {
                return "info";
            }
            if (subscript.cancelled) {
                return "primary";
            }
            if (subscript.paymentStatus === "paid") {
                return "success"
            }

            return "info"
        }

        return "info";
    }

    const showAll = () => {
        if (btnShow === "Show All") {
            setShow("Subscriptions");
        }
        else {
            setShow("Ref");
        }
    }

    return (
        <Card sx={{ height: "100%", width: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={6}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Subscriptions Overview
                </MDTypography>
                {!loading && accounts?.length > 0 && <MDButton variant="outlined" color="primary" size="small" onClick={showAll}>
                    {btnShow}
                </MDButton>}
            </MDBox>
            <MDBox pt={3} pb={2} px={2}>
                {loading &&
                    <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                        <CircularProgress color="info" />
                    </MDBox>}
                <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    mb={2}
                    sx={{ listStyle: "none" }}
                >
                    {!loading && accounts?.length > 0 ? accounts.map((account, idx) => (
                        <MDBox pt={1} pb={2} px={2} key={`${idx}-${account.profileId}`}>
                            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} pt={3} px={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={8}>
                                        <MDTypography>
                                            {account.accountName} - {account.countryCode}
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} lg={4} alignSelf="left">
                                        <MDButton
                                            variant="gradient"
                                            id={account.profileId}
                                            color={checkSubColorStatus(account)}
                                            sx={{ width: 150 }}
                                            onClick={subAction}
                                        >
                                            {checkSubStatus(account)}
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </MDBox>
                    )) : !loading && <MDBox component="ul" display="flex" flexDirection="column" pl={4} pb={2} m={0}>
                        Your Subscriptions List is Empty
                    </MDBox>}
                </MDBox>

                <MDBox px={4} pb={3}>
                    {!loading && accounts?.length > 0 && <MDButton variant="outlined" color="dark" size="small" onClick={editStripe}>
                        Cancel Subscriptions
                    </MDButton>}
                </MDBox>

            </MDBox>
        </Card>
    );
}

export default Transactions;
