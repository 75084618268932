/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";
// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import CheckOutModal from "layouts/checkOut/checkOutModal";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import {
    clearErrors,
    compareRangeData,
    getAccountById,
    getOrgAccounts,
    getSubscriptions,
    setLoading,
} from "store/actions/accountActions";
import {
    saveAdGroupTargetAcos,
} from "store/actions/adGroupActions";
import setAlert from "store/actions/alertActions";
import {
    getCampaignAdgroups,
    setLoading as setCampaignsLoading,
} from "store/actions/campaignActions";
import Calendar from "../../components/Calendar";
import { filterAdGroupData } from "../../utils/function/dateRangeTransfer";
import AllAdGroups from "./components/allAdGroups";

import { jsDateToDashedString } from "../../utils/function/dates";


const AdGroups = () => {
    const dispatch = useDispatch();
    const error = useSelector((state) => state.accounts.error);
    const loading = useSelector((state) => state.accounts.loading);
    const campaignLoading = useSelector((state) => state.campaigns.loading);
    const accounts = useSelector((state) => state.accounts.accounts);
    const account = useSelector((state) => state.accounts.account);
    const campaigns = useSelector((state) => state.campaigns.campaigns);
    const campaign = useSelector((state) => state.campaigns.campaign);
    const adGroup = useSelector((state) => state.adGroups.adgroup);

    const [accountValue, setAccountValue] = useState(null);
    const [adGroups, setAdGroups] = useState([]);
    const [campaignValue, setCampaignValue] = useState(null);
    const [mappedAccounts, setMappedAccounts] = useState([]);
    const [mappedCampaigns, setMappedCampaigns] = useState([]);
    const [acosLevelList, setAcosLevelList] = React.useState({});

    const [adGroupData, setAdGroupData] = useState([]);
    const [newCompare, setNewCompare] = useState(0);
    const [compareAdGroupData, setCompareAdGroupData] = useState([]);
    const [paidAccounts, setPaidAccounts] = useState([]);

    const [dateRange, setDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date()
    });
    const [compareDateRange, setCompareDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 30))
    });
    const [compareStyle, setCompareStyle] = useState({ display: "none" })
    const [compareOption, setCompareOption] = useState(false);

    const changeDateRange = (data) => {
        setDateRange(data);

        if (compareOption && adGroups) {
            dispatch(compareRangeData({
                ...data,
                entities: ['ADGROUP'],
                profileId: account.profileId,
                campaignId: campaign.campaignId,
            })).then((resp) => {
                setAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, adGroups))
            });
        }
    }

    const changeCompareDateRange = (data) => {
        setCompareDateRange(data);
        dispatch(setAlert("Data Loading, Please wait"));


        if (account && campaign) {
            dispatch(compareRangeData({
                ...data,
                entities: ['ADGROUP'],
                profileId: account.profileId,
                campaignId: campaign.campaignId,
            })).then((resp) => {
                setNewCompare(newCompare + 1);
                setCompareAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, adGroups))
                // dispatch(setAlert("Data Loading, Please wait"));
            });
        }
    }

    const handleCompareDR = (event) => {
        setCompareOption(event.target.checked)
        if (event.target.checked) {
            setCompareStyle({ display: "block" })
        } else {
            setCompareStyle({ display: "none" })
        }
    }

    useEffect(() => {
        if (campaign && account) {
            // dispatch(setLoading());
            dispatch(setAlert("Data Loading, Please wait"));
            dispatch(getCampaignAdgroups({
                profileId: account.profileId,
                campaignId: campaign.campaignId,
                accounts,
                campaigns
            })).then((res) => {
                const resAdGroups = res.data;
                setAdGroups(resAdGroups);

                if (compareOption && account && campaign) {
                    dispatch(compareRangeData({
                        ...dateRange,
                        entities: ['ADGROUP'],
                        profileId: account.profileId,
                        campaignId: campaign.campaignId,
                    })).then((resp) => {
                        setAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, resAdGroups))
                    });
                } else {
                    setAdGroupData([]);
                }

                if (account && campaign) {
                    dispatch(compareRangeData({
                        ...compareDateRange,
                        entities: ['ADGROUP'],
                        profileId: account.profileId,
                        campaignId: campaign.campaignId,
                    })).then((resp) => {
                        setNewCompare(newCompare + 1);
                        setCompareAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, resAdGroups))
                    });
                }
            });
        }
    }, [compareOption, account, campaign, adGroup]);

    const mapAccounts = () => {
        return accounts.map((newaccount) => {
            setMappedAccounts((prevState) => [
                ...prevState,
                {
                    label: `${newaccount.accountName} - ${newaccount.countryCode}`,
                    value: newaccount.profileId,
                },
            ].sort((a, b) => a.label > b.label ? 1 : -1));

            return newaccount;
        });
    };

    // eslint-disable-next-line no-shadow
    const mapCampaigns = (account, campaigns) => {
        if (campaigns) {
            setMappedCampaigns(campaigns.map((newCampaign) => {
                return {
                    label: newCampaign.campaignName,
                    value: newCampaign.campaignId,
                }
            }).sort((a, b) => a.label > b.label ? 1 : -1))
        }
    };

    useEffect(() => {
        if (error) {
            dispatch(setAlert(error, "error"));
            dispatch(clearErrors());
        }
    }, [error, setAlert]);

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(getOrgAccounts());
    }, []);

    useEffect(() => {
        // !Subscriptions

        dispatch(getSubscriptions()).then((res) => {
            if (res.data.length > 0) {
                const idList = [];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < res.data.length; i++) {
                    idList.push(res.data[i].profileId)
                }
                setPaidAccounts(idList);
            }
        });

        return () => {
            setPaidAccounts([]);
        };

    }, []);

    useEffect(() => {
        if (accounts.length > 0 && mappedAccounts.length === 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < accounts.length; i++) {
                accounts[i].name = `${accounts[i].accountName} - ${accounts[i].countryCode}`;
            }
            mapAccounts(accounts);
        }
    }, [accounts, mappedAccounts]);

    useEffect(() => {
        if (campaigns.length > 0 && mappedCampaigns.length === 0) {
            mapCampaigns(account, campaigns);
        }
    }, [account, campaigns, mappedCampaigns]);

    useEffect(() => {
        if (account) {
            setAccountValue({ label: `${account.accountName} - ${account.countryCode}`, value: account.profileId });
        }
    }, [account]);
    useEffect(() => {
        if (campaign) {
            setCampaignValue({ label: campaign.campaignName, value: campaign.campaignId });
        }
    }, [campaign]);

    function handleChangeAccount(e, changedvalue) {
        if (!changedvalue) {
            setAccountValue(null);
            setCampaignValue(null);
            setAdGroupData([]);
            setCompareAdGroupData([]);
            setMappedCampaigns([]);
        } else {
            setAccountValue(changedvalue);
            setMappedCampaigns([]);
            setCampaignValue(null);
            dispatch(setLoading(true));

            dispatch(getAccountById(changedvalue.value, accounts)).then((resp) => {
                mapCampaigns(account, resp.data);
            });;
        }
    }

    function handleCampaignChange(e, changedvalue) {
        if (!changedvalue) {
            setCampaignValue(null);
            setAdGroupData([]);
            setCompareAdGroupData([]);
        } else {
            setCampaignValue(changedvalue);
            dispatch(setCampaignsLoading(true));
            dispatch(setAlert("Data Loading, Please wait"));
            dispatch(getCampaignAdgroups({
                profileId: account.profileId,
                campaignId: changedvalue.value,
                accounts,
                campaigns
            })).then((res) => {
                const resAdGroups = res.data;
                setAdGroups(resAdGroups);

                if (campaign) {
                    if (compareOption) {
                        dispatch(compareRangeData({
                            ...dateRange,
                            entities: ['ADGROUP'],
                            profileId: account.profileId,
                            campaignId: campaign.campaignId
                        })).then((resp) => {
                            setAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, resAdGroups))
                        });
                    }
                    dispatch(compareRangeData({
                        ...compareDateRange,
                        entities: ['ADGROUP'],
                        profileId: account.profileId,
                        campaignId: campaign.campaignId
                    })).then((resp) => {
                        setNewCompare(newCompare + 1);
                        setCompareAdGroupData(filterAdGroupData(resp.data.adGroupsHistory, resAdGroups))
                    });
                }
            });
        }
    }

    const handleSaveAcos = () => {
        dispatch(saveAdGroupTargetAcos(acosLevelList)).then((res) => {
            setAdGroups(res.data);
            dispatch(setAlert("Save Target Acos Success"));
        });
    };

    const changeAcosList = (data) => {
        setAcosLevelList(data);
    }

    const styles = {
        container: {
            display: "flex",
            padding: "5px",
            backgroundColor: "white !important",
            borderRadius: "0.75rem",
            marginLeft: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem"
        },
        dateRange: {
            backgroundColor: "white !important",
            padding: "15px",
            minWidth: "320px"
        },
        compareToggle: {
            backgroundColor: "white !important",
            paddingTop: "15px",
            minWidth: "70px",
        },
        compareRange: {
            backgroundColor: "white !important",
            marginLeft: "-15px",
            padding: "15px",
            minWidth: "350px"
        }
    }

    return (campaignValue ?
        <DashboardLayout>
            <DashboardNavbar customTitle={account?.name} />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date Range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} height="80vh">
                        <Card id="delete-account">
                            <MDBox
                                pt={2}
                                px={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" fontWeight="medium">
                                    Ad Groups
                                </MDTypography>
                                <MDBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Autocomplete
                                        value={accountValue}
                                        disabled={loading}
                                        getOptionLabel={(option) => option.label}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={handleChangeAccount}
                                        id="controllable-states-demo"
                                        options={mappedAccounts}
                                        sx={{ width: 300, marginRight: "24px" }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Account" variant="standard" />
                                        )}
                                        isOptionEqualToValue={(option, newvalue) => option.value === newvalue.value}
                                    />
                                    <Autocomplete
                                        value={campaignValue}
                                        disabled={loading || campaignLoading}
                                        getOptionLabel={(option) => option.label}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={handleCampaignChange}
                                        id="controllable-states-demo2"
                                        options={mappedCampaigns}
                                        sx={{ width: 300, marginRight: "24px" }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Campaign" variant="standard" />
                                        )}
                                    // isOptionEqualToValue={(option, newvalue) => option.value === newvalue.value}
                                    />
                                </MDBox>
                            </MDBox>
                            {loading || campaignLoading || !compareAdGroupData.length ? (
                                <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                    {campaign && adGroup && <CircularProgress color="info" />}
                                </MDBox>
                            ) : (
                                <AllAdGroups
                                    compareOption={compareOption}
                                    changeAcosList={changeAcosList}
                                    acosLevelList={acosLevelList}
                                    adGroupData={adGroupData}
                                    compareAdGroupData={compareAdGroupData}
                                    optimizingData={adGroups}
                                />
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout >
        :
        <DashboardLayout>
            {!loading && accountValue && !paidAccounts.includes(accountValue.value) && <Modal
                // open={!loading && accountValue && !paidAccounts.includes(accountValue.value)}
                open
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: 1000,
                    bgcolor: 'background.paper',
                    borderRadius: "0.8rem",
                    boxShadow: 24,
                    p: 4,
                }}>
                    <CheckOutModal checkoutInfo={{ accountId: accountValue?.value, optimizing: true }} description="You need to subscribe to this account to be able to see your Ad Groups & Keywords data. The subscription is monthly. You can cancel at any time. Would you like to proceed?" />
                </Box>
            </Modal>}
            <DashboardNavbar customTitle={account?.name} />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} height="80vh">
                        <Card id="delete-account">
                            <MDBox
                                pt={2}
                                px={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" fontWeight="medium">
                                    Ad Groups
                                </MDTypography>
                                <MDBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Autocomplete
                                        value={accountValue}
                                        disabled={loading}
                                        getOptionLabel={(option) => option.label}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={handleChangeAccount}
                                        id="controllable-states-demo"
                                        options={mappedAccounts}
                                        sx={{ width: 300, marginRight: "24px" }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Account" variant="standard" />
                                        )}
                                        isOptionEqualToValue={(option, newvalue) => option.value === newvalue.value}
                                    />
                                    <Autocomplete
                                        value={campaignValue}
                                        disabled={loading || campaignLoading}
                                        getOptionLabel={(option) => option.label}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={handleCampaignChange}
                                        id="controllable-states-demo2"
                                        options={mappedCampaigns}
                                        sx={{ width: 300, marginRight: "24px" }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Campaign" variant="standard" />
                                        )}
                                        isOptionEqualToValue={(option, newvalue) => option.value === newvalue.value}
                                    />
                                </MDBox>
                            </MDBox>
                            <>
                                <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                    {(() => {
                                        if (!accountValue) {
                                            return "Select Account"
                                        }

                                        if (accountValue && !campaignValue) {
                                            return "Select Campaign"
                                        }

                                        return "Select Account & AdGroup"
                                    })()}
                                </MDBox>
                                <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                    <CircularProgress color="info" />
                                </MDBox>
                            </>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout>);
};

export default AdGroups;
