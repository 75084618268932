/* eslint-disable no-plusplus */
import {
  GET_USER_INFO,
  USER_ERROR,
  LOGOUT,
  CLEAR_ERRORS,
  SET_USER_LOADING,
  SIGNUP_SUCCESS,
  USER_LOADED,
  EMAIL_VERIFIED,
  AUTH_ERROR,
  EMAIL_RESEND,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_VERIFICATION_REQUIRED,
  SET_OPEN_VERIFICATION_MODAL,
  RESET_MAIL_RESEND,
  RESET_EMAIL_SENT,
  RESET_CODE_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  RESET_CODE_DISABLED,
  UPDATE_USER,
  UPDATED_PROFILE,
  CLEAR_USER_LOADING,
  CLEAR_DATA,
  LINK_SENT,
  RESET_PASS_SUCCESSS,
} from "../actions/Types";

const initialState = {
  loading: true,
  error: null,
  user: null,
  token: null,
  isAuthenticated: null,
  openVerificationModal: false,
  mailResend: 0,
  resetCodeSuccess: false,
  profileUpdated: false,
  linkSent: false,
};

// the prefix is used to reuse types
const PREFIX = "USER_";

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        loading: false,
        error: null,
        info: action.payload.data,
        failed: false,
      };

    case SIGNUP_SUCCESS: // Completed step 1 of signup
    case UPDATE_USER:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload.user, // Check user.validated
      };
    case RESET_EMAIL_SENT: // Completed step 1 of passwprd reset
      return {
        ...state,
        loading: false,
        error: null,
        user: state.isAuthenticated
          ? { ...action.payload.data.user, ...state.user }
          : action.payload.data.user,
      };
    case EMAIL_VERIFIED: // Final step of signup. will get user info and token
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };

    case LINK_SENT:
      return {
        ...state,
        linkSent: true,
        loading: false,
        error: action.payload.message,
      };

    case EMAIL_RESEND:
      return {
        ...state,
        mailResend: state.mailResend + 1,
      };
    case RESET_CODE_DISABLED:
      return {
        ...state,
        resetCodeSuccess: false,
      };
    case RESET_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        resetCodeSuccess: true,
      };

    case UPDATED_PROFILE:
      return {
        ...state,
        profileUpdated: action.payload,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authError: true,
        loading: false,
      };
    case LOGIN_SUCCESS: // Will Login Unconfirmed email
    case RESET_PASSWORD_SUCCESS: {
      localStorage.setItem('jwt', action.payload.token);

      return {
        ...state,
        isAuthenticated: true,
        // user: action.payload.data.user,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
        openVerificationModal: false,
      };
    }
    case SET_OPEN_VERIFICATION_MODAL:
      return {
        ...state,
        openVerificationModal: action.payload,
      };
    case RESET_PASS_SUCCESSS: // checks if email verified and handler set (step 2 and 3)
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: false,
        loading: false,
      };
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGIN_VERIFICATION_REQUIRED:
      return {
        ...state,
        loading: false,
        openVerificationModal: true,
        user: { email: action.payload },
      };
    case RESET_MAIL_RESEND:
      return {
        ...state,
        mailResend: 0,
      };
    case SET_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_USER_LOADING:
      return {
        ...state,
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        page: 1,
        linkSent: false,
      };
    case USER_LOADED: {
      return {
        ...state,
        // user: action.payload.user,
        user: action.payload,
        isAuthenticated: true,
        error: null,
        loading: false,
      };

    }

    case PREFIX + CLEAR_DATA: {
      // to clear a specific variable form the state, we pass it in an array (created to avoid multiple customized cases)
      const variables = action.payload;
      const tempState = state;
      for (let i = 0; variables && i < variables.length; i++) {
        tempState[variables[i]] = initialState[variables[i]];
      }

      return variables?.length > 0 ? tempState : initialState;
    }

    case LOGOUT: {
      localStorage.removeItem('jwt');

      return {
        ...state,
        loading: false,
        user: null,
        error: null,
        // info: null,
        token: null,
        isAuthenticated: null,
        resetCodeSuccess: false,
        linkSent: false,
      };
    }

    default:
      return state;
  }
};
