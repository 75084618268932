/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function Bill({ name, amount, email, url, noGutter }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const editStripe = () => {
        window.location.href = url;
    }

    return (
        <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={darkMode ? "transparent" : "grey-100"}
            borderRadius="lg"
            p={3}
            mb={noGutter ? 0 : 1}
            mt={2}
        >
            <MDBox width="100%" display="flex" flexDirection="column">
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    mb={2}
                >
                    <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                        {name}
                    </MDTypography>

                    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
                        <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={editStripe}>
                            <Icon>edit</Icon>&nbsp;edit
                        </MDButton>
                    </MDBox>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant="caption" color="text">
                        User Name:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                            {name}
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant="caption" color="text">
                        Email Address:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption" fontWeight="medium">
                            {email}
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDTypography variant="caption" color="text">
                    Amount:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium">
                        {`$${amount}`}
                    </MDTypography>
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
    noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    noGutter: PropTypes.bool,
};

export default Bill;
