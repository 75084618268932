/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Forgot from "layouts/authentication/reset-password/cover";
import Reset from "layouts/authentication/reset-password";
import Organization from "layouts/organization";
import CheckOut from "layouts/checkOut";
import Accounts from "layouts/accounts";
import AddAccountUS from "layouts/accounts/add_us";
import AddAccountEU from "layouts/accounts/add_eu";
import AddAccountFE from "layouts/accounts/add_fe";
import Campaigns from "layouts/campaigns";
import AdGroups from "layouts/adGroups";
import AdGroupDetails from "layouts/adGroups/adGroupDetails";
import CampaignDetails from "layouts/campaigns/campaignDetails";

// @mui icons
import Icon from "@mui/material/Icon";
import AccountDetails from "layouts/accounts/accountDetails";
import Unsubscribe from "./layouts/unsubscribe";

const routes = [
  {
    type: "hidden",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/accounts",
    component: <Accounts />,
  },
  {
    type: "hidden",
    name: "Account Details",
    key: "account-details",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/accounts/:profileId",
    component: <AccountDetails />,
  },
  {
    type: "hidden",
    name: "Add Account",
    key: "addaccount_na",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/addaccount_na",
    component: <AddAccountUS />,
  },
  {
    type: "hidden",
    name: "Add Account",
    key: "addaccount_eu",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/addaccount_eu",
    component: <AddAccountEU />,
  },
  {
    type: "hidden",
    name: "Add Account",
    key: "addaccount_fe",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/addaccount_fe",
    component: <AddAccountFE />,
  },
  {
    type: "collapse",
    name: "Campaigns",
    key: "campaigns",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/campaigns",
    component: <Campaigns />,
  },
  {
    type: "hidden",
    name: "Campaign Details",
    key: "campaignDetails",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/campaigns/:campaignId",
    component: <CampaignDetails />,
  },
  {
    type: "collapse",
    name: "Ad Groups",
    key: "adgroups",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/adgroups",
    component: <AdGroups />,
  },
  {
    type: "hidden",
    name: "AdGroup Details",
    key: "adgroupDetails",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/adgroups/:adGroupId",
    component: <AdGroupDetails />,
  },
  {
    type: "hidden",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "hidden",
    name: "CheckOut",
    key: "billing",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/checkout",
    component: <CheckOut />,
  },
  {
    type: "hidden",
    name: "Unsubscribe",
    key: "billing",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/unsubscribe",
    component: <Unsubscribe />,
  },
  {
    type: "hidden",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "hidden",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "hidden",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "hidden",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/forgot",
    component: <Forgot />,
  },
  {
    type: "hidden",
    name: "Reset Your Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/reset/:token",
    component: <Reset />,
  },
  {
    type: "hidden",
    name: "Organization",
    key: "organization",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/organization",
    component: <Organization />,
  },
];

export default routes;
