/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import { CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// eslint-disable-next-line import/named
import {
    compareRangeData,
    getAccountById,
    setLoading
} from "store/actions/accountActions";

import Switch from "@mui/material/Switch";
import Calendar from "../../components/Calendar";
import setAlert from "../../store/actions/alertActions";
import { saveCampaignTargetAcos } from "../../store/actions/campaignActions";
import { filterCampaignData } from "../../utils/function/dateRangeTransfer";
import { jsDateToDashedString } from "../../utils/function/dates";
import AllCampaigns from "../campaigns/components/allCampaigns";

const AccountDetails = () => {
    const { profileId } = useParams();
    const dispatch = useDispatch();
    const loading = false;

    const [campaigns, setCampaigns] = useState(null);
    const [acosLevelList, setAcosLevelList] = React.useState({});

    const [campaignData, setCampaignData] = useState([]);
    const [newCompare, setNewCompare] = useState(0);
    const [compareCampaignData, setCompareCampaignData] = useState([]);

    const [dateRange, setDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date()
    });
    const [compareDateRange, setCompareDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 30))
    });
    const [compareStyle, setCompareStyle] = useState({ display: "none" })
    const [compareOption, setCompareOption] = useState(false);

    const changeDateRange = (data) => {
        setDateRange(data);

        if (compareOption) {
            dispatch(compareRangeData({ ...data, entities: ['CAMPAIGN'] })).then((resp) => {
                setCampaignData(filterCampaignData(resp.data.campaignsHistory, campaigns))
            });
        }
    }

    const changeCompareDateRange = (data) => {
        setCompareDateRange(data);
        dispatch(setAlert("Data Loading, Please wait"));

        dispatch(compareRangeData({ ...data, entities: ['CAMPAIGN'] })).then((resp) => {
            setNewCompare(newCompare + 1);
            setCompareCampaignData(filterCampaignData(resp.data.campaignsHistory, campaigns))
            // dispatch(setAlert("Data Loading, Please wait"));
        });
    }

    const handleCompareDR = (event) => {
        setCompareOption(event.target.checked)
        if (event.target.checked) {
            setCompareStyle({ display: "block" })
        } else {
            setCompareStyle({ display: "none" })
        }
    }

    useEffect(() => {
        dispatch(setLoading());
        dispatch(setAlert("Data Loading, Please wait"));
        dispatch(getAccountById(profileId)).then((res) => {
            const accountCampaigns = res.data;
            setCampaigns(accountCampaigns);

            if (compareOption) {
                dispatch(compareRangeData({ ...dateRange, entities: ['CAMPAIGN'] })).then((resp) => {
                    setCampaignData(filterCampaignData(resp.data.campaignsHistory, accountCampaigns))
                    // dispatch(setAlert("Data Loading, Please wait"));
                });
            } else {
                setCampaignData([]);
            }
            dispatch(compareRangeData({ ...compareDateRange, entities: ['CAMPAIGN'] })).then((resp) => {
                setNewCompare(newCompare + 1);
                setCompareCampaignData(filterCampaignData(resp.data.campaignsHistory, accountCampaigns))
            });
        });
    }, [profileId, compareOption]);

    const changeAcosList = (data) => {
        setAcosLevelList(data);
    }

    const handleSaveAcos = () => {
        // dispatch(setLoading(true));
        dispatch(saveCampaignTargetAcos(acosLevelList)).then((res) => {
            setCampaigns(res.data);
            dispatch(setAlert("Save Target Acos Success"));
            // window.location.reload();
        });
    };

    const styles = {
        container: {
            display: "flex",
            padding: "5px",
            backgroundColor: "white !important",
            borderRadius: "0.75rem",
            marginLeft: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem"
        },
        dateRange: {
            backgroundColor: "white !important",
            padding: "15px",
            minWidth: "320px"
        },
        compareToggle: {
            backgroundColor: "white !important",
            paddingTop: "15px",
            minWidth: "70px",
        },
        compareRange: {
            backgroundColor: "white !important",
            marginLeft: "-15px",
            padding: "15px",
            minWidth: "350px"
        }
    }

    return (campaigns?.length > 0 ?
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date Range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} height="80vh">
                        <Card id="all-campaigns">
                            <MDBox
                                pt={2}
                                px={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" fontWeight="medium">
                                    Campaigns
                                </MDTypography>
                            </MDBox>
                            {loading || !campaigns.length || !compareCampaignData.length ? (
                                <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                    <CircularProgress color="info" />
                                </MDBox>
                            ) : (
                                <AllCampaigns
                                    compareOption={compareOption}
                                    changeAcosList={changeAcosList}
                                    acosLevelList={acosLevelList}
                                    campaignData={campaignData}
                                    compareCampaignData={compareCampaignData}
                                    optimizingData={campaigns}
                                />)}
                        </Card>

                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout >
        : <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date Range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} height="80vh">
                        <Card id="all-campaigns">
                            <MDBox
                                pt={2}
                                px={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" fontWeight="medium">
                                    Campaigns
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                {/* <CircularProgress color="info" /> */}
                                {loading && <CircularProgress color="info" />}
                                {!loading && campaigns && !campaigns.length && <>Campaigns missing for this Account</>}
                            </MDBox>
                        </Card>

                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout >
    );
};

export default AccountDetails;
