import { combineReducers } from "redux";

import userReducer from "./userReducer";
import alertReducer from "./alertReducer";
import accountReducer from "./accountReducer";
import campaignReducer from "./campaignReducer";
import adGroupReducer from "./adGroupReducer";

export default combineReducers({
  users: userReducer,
  alerts: alertReducer,
  accounts: accountReducer,
  campaigns: campaignReducer,
  adGroups: adGroupReducer,
});
