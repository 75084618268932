import axios from 'axios';

export const headers = {
  'X-DeviceType': 'Web',
  'Content-Type': 'application/json',
  'X-Language': 'EN',
};

export const instance = axios.create({
  withCredentials: true,
  // headers,
  timeout: 30000,
});

export default instance;
