import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& > * + *": {
      // marginTop: theme.spacing(2),
    },
  },
}));

const Alerts = () => {
  const alerts = useSelector((state) => state.alerts);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (alerts) {
      handleClick();
    }
  }, [alerts]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    alerts.length > 0 &&
    alerts.map((alert) => (
      <Snackbar
        style={{ maxWidth: "50vw" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={alert.id}
      >
        <Alert onClose={handleClose} severity={alert.type} elevation={6} variant="filled">
          {alert.msg}
        </Alert>
      </Snackbar>
    ))
  );
};

// Alerts.propTypes = {};

export default Alerts;
