/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'universal-cookie';

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import setAlert from "store/actions/alertActions";
import {
    getSubscriptions,
} from "store/actions/accountActions";

import { saveAdGroupTargetAcos, setOptimizeAdGroup, setOptimizeLoading } from "store/actions/adGroupActions";

import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAccountById } from "../../../store/actions/accountActions";
import { getCountryCurrency } from "../../../utils/function/currencies";
import { calRate, groupNumbersWithCommas } from "../../../utils/function/dateRangeTransfer";
import { isFiniteNumber } from "../../../utils/function/numbers";
import { setAdGroup } from "../../../store/actions/adGroupActions";

const columns = [
    { Header: "Name", accessor: "adGroupName", align: "left" },
    { Header: "optimizing", accessor: "optimizing", align: "center" },
    { Header: "Impressions", accessor: "impressions", align: "right" },
    { Header: "CTR", accessor: "ctr", align: "right" },
    { Header: "Clicks", accessor: "clicks", align: "right" },
    { Header: "Conv.Rate", accessor: "cr", align: "right" },
    { Header: "Cost", accessor: "costs", align: "right", sorting: true },
    { Header: "Avg.CPC", accessor: "avgCpc", align: "right" },
    { Header: "ACOS", accessor: "acos", align: "right" },
    { Header: "Purchases", accessor: "purchases", align: "right" },
    { Header: "Sales", accessor: "sales", align: "right" },
    { Header: "Target.ACOS", accessor: "targetAcos", align: "center" },
];

function AdGroup({ name, id, onClick }) {
    return (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography
                onClick={onClick}
                component={Link}
                to={`/adgroups/${id}`}
                variant="button"
                color="blue"
                fontWeight="medium"
                display="block"
            >
                {name}
            </MDTypography>
        </MDBox>
    );
}

function AllAdGroups(props) {
    const adGroups = useSelector((state) => state.adGroups.adGroups);
    const loading = useSelector((state) => state.adGroups.optimizeLoading);
    const account = useSelector((state) => state.accounts.account) ?? {};
    // eslint-disable-next-line no-shadow
    const state = useSelector((state) => (state));
    const [countryCode, setCountryCode] = useState(account?.countryCode ?? 'us');
    // const { countryCode = 'us' } = account;
    const { changeAcosList, acosLevelList, adGroupData, compareAdGroupData, optimizingData, compareOption } = props;
    const [targetAcos, setTargetAcos] = useState(compareAdGroupData);

    const dispatch = useDispatch();
    const cookies = new Cookies();

    const [paidAccounts, setPaidAccounts] = useState([]);

    useEffect(() => {
        // !Subscriptions

        dispatch(getSubscriptions()).then((res) => {
            if (res.data.length > 0) {
                const idList = [];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < res.data.length; i++) {
                    idList.push(res.data[i].profileId)
                }
                setPaidAccounts(idList);
            }
        });
    }, []);

    useEffect(() => {
        if (adGroups[0]?.profileId)
            dispatch(getAccountById(adGroups[0]?.profileId)).then((res) => {
                setCountryCode(res.data.data?.account?.countryCode ?? 'us');
            });
    }, []);

    const handleUpdateAdGroup = (adGroup) => {
        const itemTargetAcos = targetAcos[adGroup.idx];

        if (isFiniteNumber(itemTargetAcos) && parseFloat(itemTargetAcos) >= 0) {
            dispatch(saveAdGroupTargetAcos({ [adGroup.idx]: targetAcos[adGroup.idx] })).then((res) => {
                dispatch(setAlert("Save Target Acos Success"));
            });
        }
    };

    const handleOptimizeAdGroup = (adGroup, profile, event) => {
        if (paidAccounts.length > 0 && paidAccounts.includes(compareAdGroupData[0]?.profileId)) {
            dispatch(setOptimizeLoading());
            dispatch(setOptimizeAdGroup(adGroup, profile, event.target.checked)).then(() => {
                window.location.reload();
            });
        } else {
            // eslint-disable-next-line no-lonely-if
            if (event.target.checked) {
                cookies.set('optimizeId', compareAdGroupData[0]?.profileId, { path: '/checkout' });
                cookies.set('optimizeValue', event.target.checked, { path: '/checkout' });
                cookies.set('optimizeType', "account", { path: '/checkout' });
                window.location.href = "/checkout";
            } else {
                dispatch(setOptimizeLoading());
                dispatch(setOptimizeAdGroup(adGroup, profile, event.target.checked)).then(() => {
                    window.location.reload();
                });
            }
        }
    };

    let showData = JSON.parse(JSON.stringify(compareAdGroupData));

    // eslint-disable-next-line array-callback-return
    showData.map((adGroup) => {
        const obj = adGroupData.find(o => o.idx === adGroup.idx);
        const tAcos = optimizingData.find(o => o.adGroupId === adGroup.idx);
        if (obj) {
            adGroup.impressions_check = calRate(obj.impressions, adGroup.impressions);
            adGroup.impressions = obj.impressions;
            adGroup.ctr_check = calRate(obj.ctr, adGroup.ctr);
            adGroup.ctr = obj.ctr;
            adGroup.clicks_check = calRate(obj.clicks, adGroup.clicks);
            adGroup.clicks = obj.clicks;
            adGroup.cr_check = calRate(obj.cr, adGroup.cr);
            adGroup.cr = obj.cr;
            adGroup.costs_check = calRate(obj.costs, adGroup.costs);
            adGroup.costs = obj.costs;
            adGroup.avgCpc_check = calRate(obj.avgCpc, adGroup.avgCpc);
            adGroup.avgCpc = obj.avgCpc;
            adGroup.acos_check = calRate(obj.acos, adGroup.acos);
            adGroup.acos = obj.acos;
            adGroup.purchases_check = calRate(obj.purchases, adGroup.purchases);
            adGroup.purchases = obj.purchases;
            adGroup.sales_check = calRate(obj.sales, adGroup.sales);
            adGroup.sales = obj.sales;
        }
        adGroup.targetAcos = tAcos?.targetAcos || 0;
        adGroup.optimizing = tAcos?.optimizing;
    })

    showData = groupNumbersWithCommas(showData, ['name', 'idx', 'profileId', 'adGroupName', 'subtype', 'name', 'optimizing']);

    const rows = showData ? showData.map((adGroup) => ({
        adGroupName: (
            <AdGroup
                name={adGroup.adGroupName}
                id={adGroup.idx}
                subtype={adGroup.subtype}
                onClick={() => {
                    const matchedAdGroup = adGroups?.find(arrAdGroup => arrAdGroup.adGroupId === adGroup.idx) || JSON.parse(localStorage.getItem('adGroup'));
                    dispatch(setAdGroup(matchedAdGroup));
                    localStorage.setItem('adGroup', JSON.stringify(matchedAdGroup));
                }}
            />
        ),

        impressions: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {adGroup.impressions}<br />{adGroup.impressions_check}
            </MDTypography>
        ),

        ctr: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {adGroup.ctr}%<br />{adGroup.ctr_check}
            </MDTypography>
        ),

        clicks: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {adGroup.clicks}<br />{adGroup.clicks_check}
            </MDTypography>
        ),

        cr: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {adGroup.cr}%<br />{adGroup.cr_check}
            </MDTypography>
        ),

        costs: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {getCountryCurrency(countryCode)}{adGroup.costs ? `${adGroup.costs}` : 0}<br />{adGroup.costs_check}
            </MDTypography>
        ),

        avgCpc: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {adGroup.avgCpc}<br />{adGroup.avgCpc_check}
            </MDTypography>
        ),

        acos: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {adGroup.acos && isFiniteNumber(adGroup.acos) ? adGroup.acos : '–'}<br />
                {(() => {
                    if (compareOption) {
                        if (adGroup.acos_check && isFiniteNumber(adGroup.acos_check?.slice(1, -1)))
                            return adGroup.acos_check;

                        return '–';
                    }

                    return '';
                })()}
            </MDTypography>
        ),

        purchases: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {adGroup.purchases}<br />{adGroup.purchases_check}
            </MDTypography>
        ),

        sales: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                {getCountryCurrency(countryCode)}{adGroup.sales ? `${adGroup.sales}` : 0}<br />{adGroup.sales_check}
            </MDTypography>
        ),

        targetAcos: (
            <MDBox mt={0.5} style={{ display: "flex", flexDirection: "row" }}>
                <MDInput
                    style={{ padding: "6px 12px 0 0" }}
                    label="target acos (%)"
                    placeholder="Target ACOS: %"
                    size="small"
                    type="text"
                    defaultValue={`${adGroup.targetAcos ? adGroup.targetAcos : Math.round(adGroup.acos)}`}
                    onChange={(e) => {
                        const val = e.target.value;
                        acosLevelList[`${adGroup.idx}`] = val.replace(/[^\d.-]/g, "");
                        changeAcosList(acosLevelList);
                        setTargetAcos({ ...targetAcos, [adGroup.idx]: acosLevelList[`${adGroup.idx}`] });
                    }}
                />
                <MDBox mt={0.5}>
                    <MDButton
                        onClick={(e) => { handleUpdateAdGroup(adGroup, e) }}
                        variant="gradient"
                        color="blue"
                        disabled={loading}
                    >
                        Save
                    </MDButton>
                </MDBox>

            </MDBox>
        ),

        optimizing: (
            <MDBox mt={0.5}>
                <Switch
                    checked={adGroup.optimizing}
                    onChange={(event) => handleOptimizeAdGroup(adGroup.idx, adGroup.profileId, event)}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={loading}
                />
            </MDBox>
        ),
    })) : {};

    const sortedRows = rows.sort((a, b) => {
        const clicksA = parseInt(a.costs.props.children[1].replace(/,/g, ''), 10);
        const clicksB = parseInt(b.costs.props.children[1].replace(/,/g, ''), 10);
        return clicksB - clicksA;
    });

    return (
        <MDBox pt={3}>
            {adGroups.length ? (
                <DataTable
                    table={{
                        columns,
                        rows: sortedRows
                    }}
                    isSorted
                    entriesPerPage={false}
                    showTotalEntries
                    noEndBorder
                />
            ) : null}
        </MDBox>
    );
}

export default AllAdGroups;
