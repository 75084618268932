/* eslint-disable no-restricted-globals */
/* eslint-disable import/prefer-default-export */
export function isFiniteNumber(value) {
  if (typeof value === 'string')
    value = parseFloat(value);

  if (!isNaN(value) && isFinite(value))
    return true;

  return false;
}