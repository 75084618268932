/* eslint-disable no-underscore-dangle */
import {
  CLEAR_ERRORS,
  SET_ADGROUP_LOADING,
  SET_ADGROUP_OPTIMIZE_LOADING,
  GET_ADGROUPADS,
  ADGROUP_FAIL,
  UPDATED_ADGROUP_OPTIMIZE,
  GET_ADGROUPS,
  UPDATED_KEYWORD_OPTIMIZE,
  SET_ADGROUP
} from "../actions/Types";

const initialState = {
  loading: false,
  error: null,
  adGroups: [],
  ads: [],
  keywords: [],
  adGroup: null,
  optimizeLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADGROUPS: {

      return {
        ...state,
        loading: false,
        error: null,
        adGroups: action.payload,
        optimizeLoading: false,
      };
    }

    case GET_ADGROUPADS:
      return {
        ...state,
        loading: false,
        error: null,
        ads: action.payload?.data?.ads,
        keywords: action.payload || state.adGroups.keywords,
        adGroup: action.payload.adGroup || state.adGroups.adGroup,
        optimizeLoading: false,
      };

    case SET_ADGROUP: {
      return {
        ...state,
        adGroup: action.adGroup || state.adGroups.adGroup,
      };
    }

    case ADGROUP_FAIL:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: action.payload,
        ads: [],
        keywords: [],
        adGroup: null,
      };

    case UPDATED_ADGROUP_OPTIMIZE:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: null,

        adGroups: state.adGroups.map((adGroup) => {
          if (adGroup._id === action.payload._id) {
            return {
              ...adGroup,
              optimizing: action.payload.optimizing,
            };
          }
          return adGroup;
        }),
      };

    case UPDATED_KEYWORD_OPTIMIZE:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: null,

        keywords: state.keywords.map((keyword) => {
          if (keyword._id === action.payload._id) {
            return {
              ...keyword,
              optimizing: action.payload.optimizing,
            };
          }
          return keyword;
        }),
      };

    case SET_ADGROUP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_ADGROUP_OPTIMIZE_LOADING:
      return {
        ...state,
        optimizeLoading: true,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        optimizeLoading: false,
      };

    default:
      return state;
  }
};
