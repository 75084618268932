/* eslint-disable no-restricted-globals */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export const calRate = (base, compare) => {
    let result = "(";
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(base) || isNaN(compare) || base * compare === 0) {
        return ""
    }
    base = parseFloat(base);
    compare = parseFloat(compare);
    if (base > compare) {
        result += "+";
        result += Number(((base / compare) - 1) * 100).toFixed(2)
        result += "%";
    } else if (base < compare) {
        result += "-";
        result += Number((1 - (base / compare)) * 100).toFixed(2)
        result += "%";
    } else if (base === compare) {
        result += "0.00%";
    }
    result += ")";
    return result
}

export const readCampaignData = (campaignData, campaigns) => {
    const filterCollection = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < campaignData.length; i++) {
        const obj = campaigns.find(o => o.campaignId === campaignData[i].idx);
        if (obj) {
            filterCollection.push(campaignData[i]);
        }
    }
    return filterCollection
}

export const filterCampaignData = (campaignData, campaigns) => {
    const filterCollection = {};
    const filterResult = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < campaignData?.length; i++) {
        const obj = campaigns?.find(o => o.campaignId === campaignData[i].campaignId);
        if (obj) {
            if (!(campaignData[i].campaignId in filterCollection)) {
                filterCollection[campaignData[i].campaignId] = [campaignData[i]];
            } else {
                filterCollection[campaignData[i].campaignId].push(campaignData[i]);
            }
        }
    }

    const idxList = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const idx in filterCollection) {
        idxList.push(idx);
        let impressions = 0;
        let clicks = 0;
        let costs = 0;
        let purchases = 0;
        let sales = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < filterCollection[idx].length; i++) {
            // eslint-disable-next-line no-unused-vars
            impressions += filterCollection[idx][i].impressions ? filterCollection[idx][i].impressions : 0;
            // eslint-disable-next-line no-unused-vars
            clicks += filterCollection[idx][i].clicks ? filterCollection[idx][i].clicks : 0;
            // eslint-disable-next-line no-unused-vars
            costs += filterCollection[idx][i].cost ? filterCollection[idx][i].cost : 0;
            // eslint-disable-next-line no-unused-vars
            purchases += filterCollection[idx][i].purchases ? filterCollection[idx][i].purchases : 0;
            // eslint-disable-next-line no-unused-vars
            sales += filterCollection[idx][i].sales ? filterCollection[idx][i].sales : 0;
        }
        const obj = campaigns?.find(o => o.campaignId === idx);
        const ctr = impressions > 0 ? Number(clicks / impressions * 100).toFixed(2) : 0;
        const cr = clicks > 0 ? Number(purchases / clicks * 100).toFixed(2) : 0;
        const avgCpc = clicks > 0 ? Number(costs / clicks).toFixed(2) : 0;
        const acos = costs > 0 ? Number(costs / sales * 100).toFixed(2) : 0;
        sales = Number(sales).toFixed(2);
        costs = Number(costs).toFixed(2);
        filterResult.push({
            idx,
            profileId: obj ? obj.profileId : null,
            campaignName: obj.campaignName,
            impressions,
            ctr,
            clicks,
            cr,
            costs,
            avgCpc,
            acos,
            purchases,
            sales,
            targetAcos: obj.targetAcos,
            optimizing: obj ? obj.optimizing : false
        })
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < campaigns?.length; i++) {
        if (!idxList.includes(campaigns[i].campaignId)) {
            filterResult.push({
                idx: campaigns[i].campaignId,
                profileId: campaigns[i] ? campaigns[i].profileId : null,
                campaignName: campaigns[i].campaignName,
                impressions: 0,
                ctr: 0,
                clicks: 0,
                cr: 0,
                costs: 0,
                avgCpc: 0,
                acos: 0,
                purchases: 0,
                sales: 0,
                targetAcos: campaigns[i].targetAcos,
                optimizing: campaigns[i].optimizing
            })
        }
    }

    return filterResult;
}

export const readKeywordData = (keywordData, keywords) => {
    const filterCollection = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keywordData?.length; i++) {
        const obj = keywords.find(o => o.keywordId === keywordData[i].idx);

        if (obj) {
            keywordData[i].targetAcos = obj.targetAcos ? Object.values(obj.targetAcos)[0] : Math.round(keywordData[i].acos);
            filterCollection.push(keywordData[i]);
        }
    }

    return filterCollection
}

export const filterKeywordData = (keywordData, keywords) => {
    const filterCollection = {};
    const filterResult = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keywordData?.length; i++) {
        const obj = keywords?.find(o => o.keywordId === keywordData[i].keywordId);
        if (obj) {
            if (!(keywordData[i].keywordId in filterCollection)) {
                filterCollection[keywordData[i].keywordId] = [keywordData[i]];
            } else {
                filterCollection[keywordData[i].keywordId].push(keywordData[i]);
            }
        }
    }

    const idxList = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const idx in filterCollection) {
        idxList.push(idx);
        let impressions = 0;
        let clicks = 0;
        let costs = 0;
        let purchases = 0;
        let sales = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < filterCollection[idx].length; i++) {
            // eslint-disable-next-line no-unused-vars
            impressions += filterCollection[idx][i].impressions ? filterCollection[idx][i].impressions : 0;
            // eslint-disable-next-line no-unused-vars
            clicks += filterCollection[idx][i].clicks ? filterCollection[idx][i].clicks : 0;
            // eslint-disable-next-line no-unused-vars
            costs += filterCollection[idx][i].cost ? filterCollection[idx][i].cost : 0;

            // eslint-disable-next-line no-unused-vars
            purchases += filterCollection[idx][i].purchases ? filterCollection[idx][i].purchases : 0;
            // eslint-disable-next-line no-unused-vars
            sales += filterCollection[idx][i].sales ? filterCollection[idx][i].sales : 0;
        }

        const obj = keywords.find(o => o.keywordId === idx);

        const ctr = impressions > 0 ? Number(clicks / impressions * 100).toFixed(2) : 0;
        const cr = clicks > 0 ? Number(purchases / clicks * 100).toFixed(2) : 0;
        const avgCpc = clicks > 0 ? Number(costs / clicks).toFixed(2) : 0;
        const acos = costs > 0 ? Number(costs / sales * 100).toFixed(2) : 0;
        sales = Number(sales).toFixed(2);
        costs = Number(costs).toFixed(2);

        let keywordText = "";
        keywordText = keywords.find(keyword => keyword.keywordId === idx).keyword;

        filterResult.push({
            idx,
            profileId: obj ? obj.profileId : null,
            keywordText,
            impressions,
            ctr,
            clicks,
            cr,
            costs,
            avgCpc,
            acos,
            purchases,
            sales,
            targetAcos: obj.targetAcos || 0,
            optimizing: obj ? obj.optimizing : false
        })
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keywords?.length; i++) {
        let keywordText = "";
        let idNumber = "";
        // let targetAcos;
        keywordText = keywords[i].keyword;
        // targetAcos = keywords[i].targetAcos ? keywords[i].targetAcos : null
        idNumber = keywords[i].keywordId;

        if (!idxList.includes(idNumber)) {
            filterResult.push({
                idx: idNumber,
                profileId: keywords[i] ? keywords[i].profileId : null,
                keywordText,
                impressions: 0,
                ctr: 0,
                clicks: 0,
                cr: 0,
                costs: 0,
                avgCpc: 0,
                acos: 0,
                purchases: 0,
                sales: 0,
                targetAcos: keywords[i].targetAcos || 0,
                optimizing: keywords[i].optimizing
            })
        }
    }

    return filterResult;
}

export const readAdGroupData = (adGroupData, adGroups) => {
    const filterCollection = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < adGroupData.length; i++) {
        const obj = adGroups.find(o => o.adGroupId === adGroupData[i].idx);
        if (obj) {
            filterCollection.push(adGroupData[i]);
        }
    }
    return filterCollection
}

export const filterAdGroupData = (adGroupData, adGroups) => {
    const filterCollection = {};
    const filterResult = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < adGroupData?.length; i++) {
        const obj = adGroups?.find(o => o.adGroupId === adGroupData[i].adGroupId);
        if (obj) {
            if (!(adGroupData[i].adGroupId in filterCollection)) {
                filterCollection[adGroupData[i].adGroupId] = [adGroupData[i]];
            } else {
                filterCollection[adGroupData[i].adGroupId].push(adGroupData[i]);
            }
        }
    }

    const idxList = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const idx in filterCollection) {
        idxList.push(idx);
        let impressions = 0;
        let clicks = 0;
        let costs = 0;
        let purchases = 0;
        let sales = 0;
        const obj = adGroups?.find(o => o.adGroupId === filterCollection[idx][0].adGroupId);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < filterCollection[idx].length; i++) {
            // eslint-disable-next-line no-unused-vars
            impressions += filterCollection[idx][i].impressions ? filterCollection[idx][i].impressions : 0;
            // eslint-disable-next-line no-unused-vars
            clicks += filterCollection[idx][i].clicks ? filterCollection[idx][i].clicks : 0;
            // eslint-disable-next-line no-unused-vars
            costs += filterCollection[idx][i].cost ? filterCollection[idx][i].cost : 0;
            // if (obj.subtype === "keyword") {
            // eslint-disable-next-line no-unused-vars
            purchases += filterCollection[idx][i].purchases ? filterCollection[idx][i].purchases : 0;
            // eslint-disable-next-line no-unused-vars
            sales += filterCollection[idx][i].sales ? filterCollection[idx][i].sales : 0;
            // }
        }
        const ctr = impressions > 0 ? Number(clicks / impressions * 100).toFixed(2) : 0;
        const cr = clicks > 0 ? Number(purchases / clicks * 100).toFixed(2) : 0;
        const avgCpc = clicks > 0 ? Number(costs / clicks).toFixed(2) : 0;
        const acos = costs > 0 ? Number(costs / sales * 100).toFixed(2) : 0;
        sales = Number(sales).toFixed(2);
        costs = Number(costs).toFixed(2);
        filterResult.push({
            idx,
            profileId: obj ? obj.profileId : null,
            adGroupName: obj ? obj.adGroupName : "",
            impressions,
            ctr,
            clicks,
            cr,
            costs,
            avgCpc,
            acos,
            purchases,
            sales,
            targetAcos: obj.targetAcos,
            optimizing: obj ? obj.optimizing : false,
            subtype: obj ? obj.subtype : "none"
        })
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < adGroups?.length; i++) {
        if (!idxList.includes(adGroups[i].adGroupId)) {
            filterResult.push({
                idx: adGroups[i].adGroupId,
                profileId: adGroups[i] ? adGroups[i].profileId : null,
                adGroupName: adGroups[i].adGroupName,
                impressions: 0,
                ctr: 0,
                clicks: 0,
                cr: 0,
                costs: 0,
                avgCpc: 0,
                acos: 0,
                purchases: 0,
                sales: 0,
                targetAcos: adGroups[i].targetAcos,
                optimizing: adGroups[i].optimizing,
                subtype: adGroups[i] ? adGroups[i].subtype : "none"
            })
        }
    }

    return filterResult;
}

export const filterAccountData = (campaignData, accounts) => {
    const filterCollection = {};
    const filterResult = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < campaignData.length; i++) {
        const obj = accounts.find(o => o.profileId === campaignData[i].profileId);
        if (obj) {
            if (!(campaignData[i].profileId in filterCollection)) {
                filterCollection[campaignData[i].profileId] = [campaignData[i]];
            } else {
                filterCollection[campaignData[i].profileId].push(campaignData[i]);
            }
        }
    }

    const idxList = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const idx in filterCollection) {
        idxList.push(idx);
        let impressions = 0;
        let clicks = 0;
        let costs = 0;
        let purchases = 0;
        let sales = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < filterCollection[idx].length; i++) {
            // eslint-disable-next-line no-unused-vars
            impressions += filterCollection[idx][i].impressions ? filterCollection[idx][i].impressions : 0;
            // eslint-disable-next-line no-unused-vars
            clicks += filterCollection[idx][i].clicks ? filterCollection[idx][i].clicks : 0;
            // eslint-disable-next-line no-unused-vars
            costs += filterCollection[idx][i].cost ? filterCollection[idx][i].cost : 0;
            // eslint-disable-next-line no-unused-vars
            // old
            // purchases += filterCollection[idx][i].purchases7d ? filterCollection[idx][i].purchases7d : 0;
            purchases += filterCollection[idx][i].purchases ? filterCollection[idx][i].purchases : 0;
            // eslint-disable-next-line no-unused-vars
            // old
            // sales += filterCollection[idx][i].sales30d ? filterCollection[idx][i].sales30d : 0;
            sales += filterCollection[idx][i].sales ? filterCollection[idx][i].sales : 0;
        }
        const obj = accounts.find(o => o.profileId === filterCollection[idx][0].profileId);
        const ctr = impressions > 0 ? Number(clicks / impressions * 100).toFixed(2) : 0;
        const cr = clicks > 0 ? Number(purchases / clicks * 100).toFixed(2) : 0;
        const avgCpc = clicks > 0 ? Number(costs / clicks).toFixed(2) : 0;
        const acos = costs > 0 ? Number(costs / sales * 100).toFixed(2) : 0;
        sales = Number(sales).toFixed(2);
        costs = Number(costs).toFixed(2);
        if (obj) {
            obj.name = `${obj.accountName} - ${obj.countryCode}`;
        }
        filterResult.push({
            idx,
            name: obj ? obj.name : "",
            impressions,
            ctr,
            clicks,
            cr,
            costs,
            avgCpc,
            acos,
            purchases,
            sales,
            targetAcos: obj.targetAcos,
            optimizing: obj ? obj.optimizing : false
        })
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < accounts.length; i++) {
        if (!idxList.includes(accounts[i].profileId)) {
            accounts[i].name = `${accounts[i].accountName} - ${accounts[i].countryCode}`;
            filterResult.push({
                idx: accounts[i].profileId,
                name: accounts[i].name,
                impressions: 0,
                ctr: 0,
                clicks: 0,
                cr: 0,
                costs: 0,
                avgCpc: 0,
                acos: 0,
                purchases: 0,
                sales: 0,
                targetAcos: accounts[i].targetAcos ? Object.values(accounts[i].targetAcos)[0] : null,
                optimizing: accounts[i].optimizing
            })
        }
    }

    return filterResult;
}

// Convert a number to the desired format
export const numberWithCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const groupNumbersWithCommas = (array = [], excludeFields = []) => {
    array.forEach(obj => {
        for (const key in obj) {
            if (!excludeFields.includes(key)) {
                const value = obj[key];
                if (typeof value === "number") {
                    // Convert number properties to the desired format
                    obj[key] = numberWithCommas(value.toFixed(2));
                } else if (typeof value === "string") {
                    // Convert string properties to the desired format if they represent numbers
                    const numValue = parseFloat(value);
                    if (!isNaN(numValue)) {
                        obj[key] = numberWithCommas(numValue.toFixed(2));
                    }
                }
            }
        }
    });

    return array;
}