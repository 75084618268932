/* eslint-disable react/no-array-index-key */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function PaymentMethod(props) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { loading, paymentMethods, infoUrl } = props;

    const editStripe = () => {
        window.location.href = infoUrl;
    }

    return (
        <Card id="delete-account">
            <MDBox pt={3} px={6} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                    Payment Methods
                </MDTypography>
                {/* {paymentMethods.length > 0 ? (
                    <MDBox>
                        <MDButton variant="gradient" color="dark" onClick={editStripe}>
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            &nbsp;add payment method
                        </MDButton>
                    </MDBox>
                ) : null} */}
            </MDBox>
            <MDBox p={2} px={6} pb={5}>
                {loading &&
                    <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                        <CircularProgress color="info" />
                    </MDBox>}
                <Grid container spacing={3}>
                    {/* eslint-disable-next-line array-callback-return */}
                    {paymentMethods.length > 0 && !loading ? paymentMethods.map((method, idx) => (
                        <Grid item xs={12} md={6} key={`${idx}-${method._id}`}>
                            <MDBox
                                borderRadius="lg"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                p={3}
                                sx={{
                                    border: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                }}
                            >
                                <MDBox component="img" src={visaLogo} alt="master card" width="10%" mr={2} />
                                <MDTypography variant="h6" fontWeight="medium">
                                    ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{method.last4}
                                </MDTypography>
                                <MDBox ml="auto" lineHeight={0} color={darkMode ? "white" : "dark"}>
                                    <Tooltip title="Edit Card" placement="top" onClick={editStripe}>
                                        <Icon sx={{ cursor: "pointer" }} fontSize="small">
                                            edit
                                        </Icon>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                        </Grid>
                    ))
                        : !loading && <MDBox component="ul" display="flex" flexDirection="column" p={3} m={0}>
                            Your Payment Methods List is Empty
                        </MDBox>}
                </Grid>
            </MDBox>
        </Card>
    );
}

export default PaymentMethod;
