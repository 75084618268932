/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from "react";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

import { useDispatch, useSelector } from "react-redux";
import { checkOutSession } from "store/actions/paymentActions";
import axios from "axios";

function SubscriptionModalNew(props) {
    const { title, description, plans, accountId, optimizing } = props;

    const dispatch = useDispatch();

    return (
        <Card
            style={{ boxShadow: "0rem 0rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)" }}
        >
            <MDBox p={3}>
                <MDTypography display="inline" variant="h3" textTransform="capitalize" fontWeight="bold">
                    {title}
                </MDTypography>
                <MDBox mt={2} mb={3}>
                    <MDTypography variant="body2" component="p" color="text">
                        {description}
                    </MDTypography>
                </MDBox>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid item xs={12} md={12} lg={2} />
                    <Grid item xs={12} md={12} lg={4} />
                    <Grid item xs={12} md={12} lg={1} />
                    <Grid item xs={12} md={6} lg={2}>
                        <Link href='/accounts'>
                            <MDButton type="submit" color="warning">
                                No, Cancel
                            </MDButton>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                        <MDButton type="submit" color="info" onClick={() => {
                            dispatch(checkOutSession({ optimizing: optimizing === 'true' || optimizing === true ? true : false, profileId: accountId })).then((resp) => {
                                window.location.href = resp.data;
                            });
                        }}>
                            Yes, Proceed
                        </MDButton>
                    </Grid>
                </Grid>
            </MDBox>
        </Card >
    );
}

export default SubscriptionModalNew;
