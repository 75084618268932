/* eslint-disable no-underscore-dangle */
import {
  CONNECT_ACCOUNT,
  ACCOUNTS_FAIL,
  SET_ACCOUNTS_LOADING,
  CLEAR_ERRORS,
  GET_ORG_ACCOUNTS,
  UPDATED_PROFILE_OPTIMIZE,
  GET_PROFILE,
  SET_ACCOUNTS_OPTIMIZE_LOADING,
  SET_ACCOUNT
} from "../actions/Types";

const initialState = {
  loading: false,
  error: null,
  account: null,
  accounts: [],
  added: 0,
  updated: 0,
  optimizeLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case CONNECT_ACCOUNT: {
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: null,
        added: action.payload.addedProfiles,
        updated: action.payload.updatedProfiles,
        fetched: true,
      };
    }
    case GET_ORG_ACCOUNTS: {
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: null,
        accounts: action.payload || state.accounts,
        fetched: true,
      };
    }
    case GET_PROFILE:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: null,
        account: action.account || state.account,
        fetched: true,
        campaigns: action.payload,
      };
    case SET_ACCOUNT: {
      return {
        ...state,
        account: action.account || state.accounts.account || localStorage.getItem('account')
      }
    }
    case UPDATED_PROFILE_OPTIMIZE:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: null,
        fetched: true,
        accounts: state.accounts.map((account) => {
          if (account._id === action.payload._id) {
            return {
              ...account,
              optimizing: action.payload.optimizing,
            };
          }

          return account;
        }),
      };
    case ACCOUNTS_FAIL:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: action.payload,
        user: null,
        added: 0,
        updated: 0,
        fetched: false,
        account: null,
        accounts: [],
      };
    case SET_ACCOUNTS_LOADING:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case SET_ACCOUNTS_OPTIMIZE_LOADING:
      return {
        ...state,
        optimizeLoading: true,
        fetched: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        optimizeLoading: false,
        fetched: false,
      };
    default:
      return state;
  }
};
