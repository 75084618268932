/* eslint-disable no-underscore-dangle */
import {
  CLEAR_ERRORS,
  SET_CAMPAIGNS_LOADING,
  SET_CAMPAIGNS_OPTIMIZE_LOADING,
  GET_ADGROUPS,
  CAMPAIGNS_FAIL,
  UPDATED_CAMPAIGN_OPTIMIZE,
  GET_PROFILE,
  SET_CAMPAIGN,
} from "../actions/Types";

const initialState = {
  loading: false,
  error: null,

  campaigns: [],
  campaign: null,
  optimizeLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADGROUPS: {

      return {
        ...state,
        loading: false,
        error: null,
        campaign: action.campaign || state.campaign,
        adGroups: action.payload,
        optimizeLoading: false,
      };
    }

    case UPDATED_CAMPAIGN_OPTIMIZE:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: null,
        fetched: true,
        campaigns: state.campaigns.map((campaign) => {
          if (campaign._id === action.payload._id) {
            return {
              ...campaign,
              optimizing: action.payload.optimizing,
            };
          }
          return campaign;
        }),
      };

    case SET_CAMPAIGN: {
      return {
        ...state,
        campaign: action.campaign || state.campaigns.campaign || localStorage.getItem('campaign'),
      };
    }

    case GET_PROFILE:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: null,
        campaigns: action.payload,
      };

    case CAMPAIGNS_FAIL:
      return {
        ...state,
        loading: false,
        optimizeLoading: false,
        error: action.payload,
        adGroups: [],
        campaigns: [],
        campaign: null,
      };
    case SET_CAMPAIGNS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_CAMPAIGNS_OPTIMIZE_LOADING:
      return {
        ...state,
        optimizeLoading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        optimizeLoading: false,
      };
    default:
      return state;
  }
};
