/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";
// Material Dashboard 2 React example components
import { CircularProgress } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { useDispatch, useSelector } from "react-redux";
import {
    clearErrors,
    compareRangeData,
    getOrgAccounts,
    getSubscriptions,
    saveAccountTargetAcos,
    setLoading,
    updateAccountSubscriptions
} from "store/actions/accountActions";
import setAlert from "store/actions/alertActions";

import { checkWholeSub, retrieveSession } from "store/actions/paymentActions";

import Switch from "@mui/material/Switch";
import Calendar from "../../components/Calendar";
import AllAccounts from "./components/allAccounts";

import { jsDateToDashedString } from "../../utils/function/dates";

import {
    filterAccountData
} from "../../utils/function/dateRangeTransfer";
import { WaitLoadingData } from "./WaitLoadingData";


const Accounts = () => {
    const connectUrlNA = process.env.REACT_APP_AZ_CONNECT_URL_NA;
    const connectUrlEU = process.env.REACT_APP_AZ_CONNECT_URL_EU;
    const connectUrlFE = process.env.REACT_APP_AZ_CONNECT_URL_FE;

    const dispatch = useDispatch();

    const error = useSelector((state) => state.accounts.error);
    const loading = useSelector((state) => state.accounts.loading);
    const fetched = useSelector((state) => state.accounts.fetched);
    const updated = useSelector((state) => state.accounts.updated);
    const added = useSelector((state) => state.accounts.added);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [acosLevelList, setAcosLevelList] = React.useState({});

    const [accounts, setAccounts] = useState([]);
    const [paidSubscriptionsLoading, setPaidSubscriptionsLoading] = useState(true);
    const [paidSubscriptions, setPaidSubscriptions] = useState([]);

    const [accountData, setAccountData] = useState([]);
    const [newCompare, setNewCompare] = useState(0);
    const [compareAccountData, setCompareAccountData] = useState([]);
    const [waitLoadingData, setWaitLoadingData] = useState(false);

    const [dateRange, setDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date()
    });
    const [compareDateRange, setCompareDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 30))
    });
    const [compareStyle, setCompareStyle] = useState({ display: "none" })
    const [compareOption, setCompareOption] = useState(false);

    const [checkDataReady, setCheckDataReady] = useState(true);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let button = document.getElementById('add-new-accounts');
    let direction = -1;

    function moveButton() {
        if (button?.style) {
            if (direction < 0) {
                button.style.transform = `translateX(${direction * 10}px)`;
                button.style.color = "#00b2e3";
                button.style.background = "#fff"
            } else {
                button.style.transform = `translateX(${0}px)`;
                button.style.color = "#fff";
                button.style.background = "#000"
            }

            direction *= -1;
        }
    }

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!accounts.length && !loading) {
            button = document.getElementById('add-new-accounts');
            direction = -1;

            const buttonEffectsInterval = setInterval(moveButton, 3000);

            return () => {
                clearInterval(buttonEffectsInterval);
            }
        }
    }, [accounts, loading]);

    useEffect(() => {
        if (error) {
            dispatch(setAlert(error, "error"));
            dispatch(clearErrors());
        }
    }, [error, setAlert]);

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(setAlert("Data Loading, Please wait"));
        dispatch(getOrgAccounts()).then((res) => {
            const effectAccounts = res.data;
            setAccounts(effectAccounts);

            if (compareOption) {
                dispatch(compareRangeData({ ...dateRange, entities: ['CAMPAIGN'] })).then((resp) => {
                    const newAccData = filterAccountData(resp.data.campaignsHistory, effectAccounts);
                    setAccountData(newAccData);

                    setTimeout(() => {
                        let salesTotal = 0;
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < accountData.length; i++) {
                            salesTotal += accountData[i].sales;
                        }
                    }, 10 * 1000)
                });
            }
            else {
                setAccountData([]);
            }
            dispatch(compareRangeData({ ...compareDateRange, entities: ['CAMPAIGN'] })).then((resp) => {

                setNewCompare(newCompare + 1);
                const filterAccountDataResCOMPARE = filterAccountData(resp.data.campaignsHistory, effectAccounts);
                setCompareAccountData(filterAccountData(resp.data.campaignsHistory, effectAccounts))
            });
        });
    }, [added, updated, compareOption]);

    useEffect(() => {
        if (accounts?.length)
            setWaitLoadingData(Date.now() - 3 * 60 * 60 * 1000 < (new Date(accounts[0]?.createdAt)).getTime());
    }, [accounts]);

    useEffect(() => {
        // ! Subscriptions
        dispatch(retrieveSession()).then(() => {
            dispatch(checkWholeSub()).then(() => {
                dispatch(getSubscriptions()).then((res) => {
                    if (res.data.length > 0) {
                        const idList = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < res.data.length; i++) {
                            idList.push(res.data[i])
                        }

                        setPaidSubscriptions(idList);
                    }

                    setPaidSubscriptionsLoading(false);
                });
                dispatch(updateAccountSubscriptions());
            })
        })
    }, []);

    const handleSaveAcos = () => {
        dispatch(saveAccountTargetAcos(acosLevelList)).then((res) => {
            setAccounts(res.data.data.accounts);
            dispatch(setAlert("Save Target Acos Success"));
        });
    };

    const changeAcosList = (data) => {
        setAcosLevelList(data);
    }

    const changeDateRange = (data) => {
        setDateRange(data);

        if (compareOption) {
            dispatch(compareRangeData({ ...data, entities: ['CAMPAIGN'] })).then((resp) => {
                setAccountData(filterAccountData(resp.data.campaignsHistory, accounts))
            });
        }
    }

    const changeCompareDateRange = (data) => {
        setCompareDateRange(data);
        dispatch(setAlert("Data Loading, Please wait"));
        dispatch(compareRangeData({ ...data, entities: ['CAMPAIGN'] })).then((resp) => {
            setNewCompare(newCompare + 1);
            setCompareAccountData(filterAccountData(resp.data.campaignsHistory, accounts))
        });
    }

    const handleCompareDR = (event) => {
        setCompareOption(event.target.checked)
        if (event.target.checked) {
            setCompareStyle({ display: "block" })
        }
        else {
            setCompareStyle({ display: "none" });
        }
    }

    const styles = {
        container: {
            display: "flex",
            padding: "5px",
            backgroundColor: "white !important",
            borderRadius: "0.75rem",
            marginLeft: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem"
        },
        dateRange: {
            backgroundColor: "white !important",
            padding: "15px",
            minWidth: "320px"
        },
        compareToggle: {
            backgroundColor: "white !important",
            paddingTop: "15px",
            minWidth: "70px",
        },
        compareRange: {
            backgroundColor: "white !important",
            marginLeft: "-15px",
            padding: "15px",
            minWidth: "350px"
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar dataReady={checkDataReady} />
            <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid sx={styles.container}>
                        <Grid item xs={12} md={6} lg={2} sx={styles.dateRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(dateRange.startDate), endDate: jsDateToDashedString(dateRange.endDate) }}
                                    changeDateRange={changeDateRange}
                                    placeholder="Date Range"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1} sx={styles.compareToggle}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                lineHeight={1}
                            >
                                <MDTypography display="block">
                                    <Switch
                                        checked={compareOption}
                                        onChange={(event) => handleCompareDR(event)}
                                    />
                                </MDTypography>
                                <div style={{ paddingLeft: "5.5px" }}>
                                    <MDTypography variant="caption">compare</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} sx={styles.compareRange}>
                            <MDBox
                                mb={1.5}
                                alignItems="center"
                                sx={compareStyle}
                                className="right-calendar"
                            >
                                <Calendar
                                    defaultValue={{ startDate: jsDateToDashedString(compareDateRange.startDate), endDate: jsDateToDashedString(compareDateRange.endDate) }}
                                    changeDateRange={changeCompareDateRange}
                                    placeholder="Compare to"
                                />
                            </MDBox>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} height="80vh">
                        <Card id="delete-account">
                            <MDBox
                                pt={2}
                                px={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                {/* <button id="purchase" style={{ color: "blue" }} onClick={() => {
                                    window.gtag("event", "purchase", {
                                        transaction_id: Date.now(),
                                        value: process.env.NODE_ENV !== 'PRODUCTION' ? 0.50 : 99,
                                        currency: 'USD',
                                        label: "TEST | Subscription purchase event",
                                        coupon: JSON.stringify({
                                            transaction_id: Date.now(),
                                            value: process.env.NODE_ENV !== 'PRODUCTION' ? 0.50 : 99,
                                            currency: 'USD',
                                            label: "TEST | Subscription purchase event",
                                            info: "Whatever field will by default be visible in analytics if the field is among events recommended fields"
                                        })
                                    });
                                }}>PURCHASE</button> */}
                                <MDTypography variant="h6" fontWeight="medium">
                                    Connected Accounts
                                </MDTypography>
                                <MDBox id="add-new-accounts-box">
                                    <MDButton
                                        id="add-new-accounts"
                                        variant="gradient"
                                        color="dark"
                                        disabled={loading}
                                        onClick={handleClick}
                                        aria-controls={open ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                    >
                                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                        &nbsp;add new accounts
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                            {(!accounts.length || !compareAccountData.length || loading) && !waitLoadingData ? (
                                <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                    {/* <CircularProgress color="info" /> */}
                                    {(() => {
                                        if ((!compareAccountData.length || loading) && accounts.length) {
                                            return <CircularProgress color="info" />;
                                        }

                                        return <p> <br />You haven&apos;t connected any account yet. Click on “Add New Accounts” button to add a new account.</p>;
                                    })()}
                                </MDBox>
                            ) : (waitLoadingData ?
                                <MDBox display="flex" justifyContent="center" alignItems="center" m={4}>
                                    <WaitLoadingData />
                                </MDBox> :
                                <AllAccounts
                                    compareOption={compareOption}
                                    changeAcosList={changeAcosList}
                                    acosLevelList={acosLevelList}
                                    accountData={accountData}
                                    compareAccountData={compareAccountData}
                                    optimizingData={accounts}
                                    paidSubscriptions={paidSubscriptions}
                                    paidSubscriptionsLoading={paidSubscriptionsLoading}
                                />
                            )}
                        </Card>
                        {/* {(() => {
                            return !loading && !accounts.length && <p> <br />You haven&apos;t connected any account yet. Click on “Add New Accounts” button to add a new account.</p>
                        })()} */}
                    </Grid>
                </Grid>
            </MDBox>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <Link
                    href={connectUrlNA} target="_parent" rel="noreferrer">
                    <MenuItem>US Accounts</MenuItem>
                </Link>
                <Link href={connectUrlEU} target="_parent" rel="noreferrer">
                    <MenuItem onClick={handleClose}>EU Accounts</MenuItem>
                </Link>
                <Link href={connectUrlFE} target="_parent" rel="noreferrer">
                    <MenuItem onClick={handleClose}>FE Accounts</MenuItem>
                </Link>
            </Menu>
            <Footer />
        </DashboardLayout >
    );
};

export default Accounts;
